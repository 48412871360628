import React from 'react';
import Header from '../../components/Header';
import { useTranslation } from 'react-i18next';
import SourceReference from '../../components/SourceReference';
import SectionHeader from '../../components/SectionHeader';
import photoCollection from '../../data/photoLibrary.json';
import { PhotoCollection, Photo } from '../../common/Types';

function PhotoLibrary() {
	const { t } = useTranslation(['translation', 'photos']);
	const getTextForPhotos = (key: string): string => {
		return t(key, { ns: 'photos' });
	};
	const photos = photoCollection as PhotoCollection;
	const photoLibrary = photos.library;

	return (
		<div id="m3Touring-photo-library" className="g81-page">
			<div className="row">
				<Header title={getTextForPhotos('header')} />
			</div>
			<div className="row">
				<div className="col mb-5">
					{photoLibrary.map((photo: Photo) => (
						<a
							key={photo.imgPath}
							href={`${photos.basePath}/${photo.imgPath}`}
							target="_blank"
							rel="noreferrer"
						>
							<img
								src={`${photos.basePath}/${photo.thumbnail}`}
								alt={photo.subtitle}
								className="g81-libraryPhoto"
							/>
						</a>
					))}
				</div>
			</div>
			<div className="row">
				<div className="col mb-5"></div>
			</div>
			<div className="row">
				<div className="col mb-5">
					<SourceReference
						source="G81Adventures.com"
						dateAccessed="2024-07-11"
						url="https://g81adventures.com"
					/>
				</div>
			</div>
		</div>
	);
}

export default PhotoLibrary;
