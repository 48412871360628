import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavigationRouteItem } from '../common/Types';

interface NavigationProps {
	items: NavigationRouteItem[];
}

const Navigation: React.FC<NavigationProps> = ({ items }) => {
	const { t, i18n } = useTranslation();

	const renderMenuItem = (item: NavigationRouteItem) => {
		return (
			<li key={item.textKey} className="nav-item">
				<a className="nav-link active" aria-current="page" href={`/${i18n.language}/${item.target}`}>
					<b>{t(item.textKey)}</b>
				</a>
				{item.children && renderNavigation(item.children, 1, item.textKey)}
			</li>
		);
	};

	const renderSectionItem = (item: NavigationRouteItem) => {
		return (
			<React.Fragment key={'section_' + item.textKey}>
				<h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
					<span>{t(item.textKey)}</span>
				</h6>
				{item.children && renderNavigation(item.children, 1, item.textKey)}
			</React.Fragment>
		);
	};

	const renderNavigation = (navItems: NavigationRouteItem[], level: number = 0, keyName: string) => {
		return (
			<ul
				key={'navigation-level-' + keyName + '_' + level.toString()}
				className={'nav flex-column ' + (level > 0 ? 'mx-3' : 'mx-0')}
			>
				{navItems.map((item) => {
					if (item.isSectionNodeOnly) {
						return renderSectionItem(item);
					} else {
						return renderMenuItem(item);
					}
				})}
			</ul>
		);
	};

	return (
		<div className="sidebar col-md-3 col-lg-2 p-0">
			<div className="offcanvas-md offcanvas-end" id="sidebarMenu">
				<div className="offcanvas-header">
					<h5 className="offcanvas-title" id="sidebarMenuLabel">
						{t('app.title')}
					</h5>
					<button
						type="button"
						className="btn-close"
						data-bs-dismiss="offcanvas"
						data-bs-target="#sidebarMenu"
						aria-label="Close"
					></button>
				</div>
				<div className="g81-nav-list">{renderNavigation(items, 0, 'root')}</div>
			</div>
		</div>
	);
};

export default Navigation;
