import React from 'react';
import Header from '../../components/Header';
import { useTranslation } from 'react-i18next';
import ColorPreview from '../../components/ColorPreview';
import availableColors from '../../data/exteriorColors.json';
import SectionHeader from '../../components/SectionHeader';

function ExteriorColors() {
	const { t, i18n } = useTranslation(['translation', 'colors']);
	const currentLanguage = i18n.language;

	const getTextForColors = (key: string): string => {
		return t(key, { ns: 'colors' });
	};

	const standardColors = Object.entries(availableColors)
		.filter(([key, value]) => value.isStandard)
		.sort(([, valueA], [, valueB]) => {
			return valueA.colorRange.localeCompare(valueB.colorRange);
		});
	const individualColors = Object.entries(availableColors)
		.filter(([key, value]) => !value.isStandard)
		.sort(([, valueA], [, valueB]) => {
			return valueA.colorRange.localeCompare(valueB.colorRange);
		});

	const textDE = (
		<div>
			<p>
				Ich habe versucht die Farben so gut wie möglich abzubilden. Ein Bildschirm kann den Lack niemals
				wirklich korrekt wiedergeben, daher ist es eine Interpretation und es gibt keine Garantie für die
				Korrektheit. Zudem fehlen weitere Nuance wie sie z.B. bei Dravitgrau mit einem Goldschimmer der Fall
				sind. Es soll einen Eindruck von der verfügbaren Farbpalette vermitteln und bestenfalls als
				Orientierung dienen, um eine Vorstellung zu bekommen.
			</p>
			<p>
				Der Original BMW Indivual Visualizer ist hier zu finden:{' '}
				<a href="https://individual.bmw-m.com/de-DE/G81-11GB-C1D" target="_blank" rel="noreferrer">
					BMW Individual Visualizer für den M3 Touring
				</a>
			</p>
			<p>
				Für weitere Impressionen (auf Modellen der G8x-Serie) folgende Links mit Fotos:
				<br />
				<a href="https://g80.bimmerpost.com/forums/showthread.php?t=2067706" target="_blank" rel="noreferrer">
					Bimmerpost.com Forum - McKenna BMW M Individual collection (all available for purchase)
				</a>
				<br />
				Weitere Farben auch hier gepostet:
				<br />
				<a href="https://g80.bimmerpost.com/forums/forumdisplay.php?f=866" target="_blank" rel="noreferrer">
					Bimmerpost.com Forum - M3 / M4 Photos, Videos, Builds
				</a>
				<br />
				Natürlich gibt es zahlreiche andere Ressourcen im Internet.
			</p>
		</div>
	);
	const textEN = (
		<div>
			<p>
				I tried to depict the colors as accurately as possible. A screen can never truly display the paint
				correctly, so it is an interpretation and there is no guarantee of correctness. Additionally, certain
				nuances are missing, such as the gold shimmer present in Dravit Grey. It aims to give an impression of
				the available color palette and, at best, serve to get an idea.
			</p>
			<p>
				The original BMW Individual Visualizer can be found here:{' '}
				<a href="https://individual.bmw-m.com/de-DE/G81-11GB-C1D" target="_blank" rel="noreferrer">
					BMW Individual Visualizer for the M3 Touring
				</a>
			</p>
			<p>
				For further impressions (on models of the G8x series), the following links with photos are available:
				<br />
				<a href="https://g80.bimmerpost.com/forums/showthread.php?t=2067706" target="_blank" rel="noreferrer">
					Bimmerpost.com Forum - McKenna BMW M Individual collection (all available for purchase)
				</a>
				<br />
				More colors are also posted here:
				<br />
				<a href="https://g80.bimmerpost.com/forums/forumdisplay.php?f=866" target="_blank" rel="noreferrer">
					Bimmerpost.com Forum - M3 / M4 Photos, Videos, Builds
				</a>
				<br />
				And of course there are multiple other resources available in the internet.
			</p>
		</div>
	);
	return (
		<div id="exteriorColors" className="g81-page">
			<div className="row">
				<Header title={t('app.navigation.exteriorColors')} />
			</div>
			<div className="row">
				<div className="col">
					{currentLanguage === 'en' && textEN}
					{currentLanguage === 'de' && textDE}
				</div>
			</div>
			<div className="row">
				<div className="col">
					<SectionHeader title={getTextForColors('headers.standardColors')} />
				</div>
			</div>
			<div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-6">
				{standardColors.map(([key, value]) => (
					<div className="col" key={key}>
						<ColorPreview
							name={value.name}
							code={value.code}
							type={value.type}
							rgbBase={value.rgb}
							colorRange={value.colorRange}
						/>
					</div>
				))}
			</div>
			<div className="row">
				<div className="col">
					<SectionHeader title={getTextForColors('headers.individualColors')} />
				</div>
			</div>
			<div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-6">
				{individualColors.map(([key, value]) => (
					<div className="col" key={key}>
						<ColorPreview
							key={key}
							name={value.name}
							code={value.code}
							type={value.type}
							rgbBase={value.rgb}
							colorRange={value.colorRange}
						/>
					</div>
				))}
			</div>
		</div>
	);
}

export default ExteriorColors;
