import React from 'react';
import Header from '../../components/Header';
import { useTranslation } from 'react-i18next';
import youTubeData from '../../data/youtube.json';
import SectionHeader from '../../components/SectionHeader';
import { YouTubeSource } from '../../common/Types';
const moment = require('moment');

function YouTube() {
	const { t } = useTranslation(['translation', 'colors', 'youtube']);
	const getTextForColors = (key: string): string => {
		return t(key, { ns: 'colors' });
	};
	const getTextForYouTube = (key: string): string => {
		return t(key, { ns: 'youtube' });
	};
	youTubeData.sort((a, b) => moment(b.publishedOn, 'YYYY-MM-DD').diff(moment(a.publishedOn, 'YYYY-MM-DD')));
	return (
		<div id="m3Touring-media" className="g81-page">
			<div className="row">
				<Header title={getTextForYouTube('header')} />
			</div>
			<div className="row">
				<div className="col mb-5">
					<SectionHeader title={getTextForYouTube('header')} icon="bi-youtube" />
					<p>{t('disclaimer')}</p>
					<table className="table table-striped">
						<thead className="table-light">
							<tr>
								<th>{getTextForYouTube('table.publishedOn')}</th>
								<th>{getTextForYouTube('table.title')}</th>
								<th>{getTextForYouTube('table.channel')}</th>
								<th>{getTextForYouTube('table.categories')}</th>
								<th>{getTextForYouTube('table.language')}</th>
								<th>{getTextForYouTube('table.shownExteriorColor')}</th>
								<th>{getTextForYouTube('table.shownInteriorColor')}</th>
								<th>{getTextForYouTube('table.extras')}</th>
							</tr>
						</thead>
						<tbody>
							{youTubeData.map((video: YouTubeSource, index) => (
								<tr key={index}>
									<td>{moment(video.publishedOn).format('L')}</td>
									<td>
										<a href={video.videoUrl} target="_blank" rel="noreferrer">
											{video.videoTitle}
										</a>
									</td>
									<td>
										<a href={video.channelUrl} target="_blank" rel="noreferrer">
											{video.channelName}
										</a>
									</td>
									<td>{video.contentType.join(', ')}</td>
									<td>{video.language}</td>
									<td>
										{video.shownExteriorColorCode
											? getTextForColors(video.shownExteriorColorCode) +
											  ' (' +
											  video.shownExteriorColorCode +
											  ')'
											: '-'}
									</td>
									<td>{video.shownInteriorColor ? getTextForColors(video.shownInteriorColor) : '-'}</td>
									<td>{video.extras ? video.extras.join(', ') : '-'}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
			<div className="row">
				<div className="col mb-5">
					<SectionHeader title={getTextForYouTube('youtubeOfficial.header')} icon="bi-youtube" />
					<table className="table table-striped">
						<thead className="table-light">
							<tr>
								<th>{getTextForYouTube('table.publishedOn')}</th>
								<th>{getTextForYouTube('table.title')}</th>
								<th>{getTextForYouTube('table.channel')}</th>
								<th>{getTextForYouTube('table.categories')}</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>{moment('2022-07-10').format('L')}</td>
								<td>
									<a href="https://www.youtube.com/watch?v=ls0bZe9VP0Y" target="_blank" rel="noreferrer">
										THE M3 TOURING. All you need to know.
									</a>
								</td>
								<td>
									<a href="https://www.youtube.com/@BMWM" target="_blank" rel="noreferrer">
										BMW M
									</a>
								</td>
								<td>Documentation, Promotion</td>
							</tr>
							<tr>
								<td>{moment('2022-06-22').format('L')}</td>
								<td>
									<a href="https://www.youtube.com/watch?v=5wgU_Qq6KhQ" target="_blank" rel="noreferrer">
										THE M3 TOURING. A DREAM COME TRUE.
									</a>
								</td>
								<td>
									<a href="https://www.youtube.com/@BMWM" target="_blank" rel="noreferrer">
										BMW M
									</a>
								</td>
								<td>Werbung, Promotion</td>
							</tr>
							<tr>
								<td>{moment('2022-06-17').format('L')}</td>
								<td>
									<a href="https://www.youtube.com/watch?v=i6F5cp79e6Y" target="_blank" rel="noreferrer">
										THE M3 TOURING. Fast Lap Nordschleife.
									</a>
								</td>
								<td>
									<a href="https://www.youtube.com/@BMWM" target="_blank" rel="noreferrer">
										BMW M
									</a>
								</td>
								<td>Onboard, Promotion</td>
							</tr>
							<tr>
								<td>{moment('2022-06-16').format('L')}</td>
								<td>
									<a href="https://www.youtube.com/watch?v=nzo56jb_irQ" target="_blank" rel="noreferrer">
										WE ARE M - THE M3 TOURING – B’Ring It On.
									</a>
								</td>
								<td>
									<a href="https://www.youtube.com/@BMWM" target="_blank" rel="noreferrer">
										BMW M
									</a>
								</td>
								<td>Onboard, Promotion</td>
							</tr>
							<tr>
								<td>{moment('2022-06-02').format('L')}</td>
								<td>
									<a href="https://www.youtube.com/watch?v=k1J3w8Tqysk" target="_blank" rel="noreferrer">
										WE ARE M - THE M3 TOURING – Plant Munich.
									</a>
								</td>
								<td>
									<a href="https://www.youtube.com/@BMWM" target="_blank" rel="noreferrer">
										BMW M
									</a>
								</td>
								<td>Onboard, Promotion</td>
							</tr>
							<tr>
								<td>{moment('2022-05-16').format('L')}</td>
								<td>
									<a href="https://www.youtube.com/watch?v=J6I3KZoKZeQ" target="_blank" rel="noreferrer">
										WE ARE M - THE M3 TOURING – The Ultimate Test.
									</a>
								</td>
								<td>
									<a href="https://www.youtube.com/@BMWM" target="_blank" rel="noreferrer">
										BMW M
									</a>
								</td>
								<td>Onboard, Promotion</td>
							</tr>
							<tr>
								<td>{moment('2022-04-25').format('L')}</td>
								<td>
									<a href="https://www.youtube.com/watch?v=nurIf0wz9J0" target="_blank" rel="noreferrer">
										WE ARE M - THE M3 TOURING – The Secret Recipe.
									</a>
								</td>
								<td>
									<a href="https://www.youtube.com/@BMWM" target="_blank" rel="noreferrer">
										BMW M
									</a>
								</td>
								<td>Onboard, Promotion</td>
							</tr>
							<tr>
								<td>{moment('2022-04-11').format('L')}</td>
								<td>
									<a href="https://www.youtube.com/watch?v=2hT0HYkms8g" target="_blank" rel="noreferrer">
										WE ARE M - THE M3 Touring – Ignition On.
									</a>
								</td>
								<td>
									<a href="https://www.youtube.com/@BMWM" target="_blank" rel="noreferrer">
										BMW M
									</a>
								</td>
								<td>Onboard, Promotion</td>
							</tr>
							<tr>
								<td>{moment('2022-03-28').format('L')}</td>
								<td>
									<a href="https://www.youtube.com/watch?v=HOYiu1oNc1A" target="_blank" rel="noreferrer">
										WE ARE M - THE M3 Touring – The First Prototype.
									</a>
								</td>
								<td>
									<a href="https://www.youtube.com/@BMWM" target="_blank" rel="noreferrer">
										BMW M
									</a>
								</td>
								<td>Onboard, Promotion</td>
							</tr>
							<tr>
								<td>{moment('2022-03-14').format('L')}</td>
								<td>
									<a href="https://www.youtube.com/watch?v=fF66OPqEC7k" target="_blank" rel="noreferrer">
										WE ARE M - THE M3 Touring - How It All Began.
									</a>
								</td>
								<td>
									<a href="https://www.youtube.com/@BMWM" target="_blank" rel="noreferrer">
										BMW M
									</a>
								</td>
								<td>Onboard, Promotion</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
}

export default YouTube;
