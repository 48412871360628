import { Outlet } from 'react-router';
import { NavigationRouteItem, NavigationRoutes } from '../common/Types';
import Footer from '../components/Footer';
import routesStructure from './routes.json';
import Navigation from '../components/Navigation';
import LanguageSelection from '../components/LanguageSelection';
import HomeLink from '../components/HomeLink';
import withLanguageFallback from '../common/LanguageFallback';
import React from 'react';
import { Utils } from '../common/Utils';

function Root() {
	const shouldShowDevPages = process.env.REACT_APP_SHOW_DEV_PAGES === 'true';
	const renderAnalytics = () => {
		const target = process.env.REACT_APP_ANALYTICS_URL;
		if (target) {
			const _window = Utils.getWindow();
			const _mtm = (_window._mtm = _window._mtm || []);
			_mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });
			const scriptTag = document.createElement('script');
			const scriptTagElement = document.getElementsByTagName('script')[0];
			scriptTag.async = true;
			scriptTag.src = target;
			if (scriptTagElement && scriptTagElement.parentNode) {
				scriptTagElement.parentNode.insertBefore(scriptTag, scriptTagElement);
			}
		}
	};
	const filterNavigationItems = (items: NavigationRouteItem[]): NavigationRouteItem[] => {
		return items.reduce((acc: NavigationRouteItem[], item) => {
			if (!item.isDev) {
				if (item.children) {
					const filteredChildren = filterNavigationItems(item.children);
					if (filteredChildren.length > 0) {
						acc.push({ ...item, children: filteredChildren } as NavigationRouteItem);
					}
				} else {
					acc.push(item);
				}
			}
			return acc;
		}, [] as NavigationRouteItem[]); // Specify the initial value and type for `acc`
	};

	const navigationRoutes = routesStructure as NavigationRoutes;
	const filteredNavItems = shouldShowDevPages
		? navigationRoutes.navigation
		: filterNavigationItems(navigationRoutes.navigation);

	React.useEffect(() => {
		window.process = {
			...window.process,
		};
		renderAnalytics();
	}, []);
	return (
		<div className="g81-logo-bg g81-signature-line">
			<header className="navbar sticky-top flex-md-nowrap p-0 shadow">
				<HomeLink />
				<ul className="navbar-nav ms-auto flex-row d-md-none">
					<li className="nav-item text-nowrap">
						<button
							className="g81-nav-toggle nav-link px-3"
							type="button"
							data-bs-toggle="offcanvas"
							data-bs-target="#sidebarMenu"
							aria-controls="sidebarMenu"
							aria-expanded="false"
							aria-label="Toggle navigation"
						>
							<i className="bi bi-list"></i>
						</button>
					</li>
				</ul>
			</header>
			<div className="g81-logo-header d-flex justify-content-end">
				<div className="g81-language-header">
					<LanguageSelection items={routesStructure.languages} />
				</div>
			</div>
			<div className="container-fluid">
				<div className="row">
					<Navigation key="navigation-root" items={filteredNavItems} />
					<main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
						<Outlet />
					</main>
				</div>
			</div>
			<div id="footer">
				<Footer />
			</div>
		</div>
	);
}

export default withLanguageFallback(Root);
