import React from 'react';

interface SectionHeaderProps {
	title: string;
	icon?: string;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({ title, icon }) => {
	return (
		<>
			{icon ? (
				<div id="header" className="fs-2 mb-3 d-flex align-items-center">
					{/*<i className={'bi me-2 ' + icon}></i>*/}
					{title && <h4 className="mb-0">{title}</h4>}
				</div>
			) : (
				<div id="header">{title && <h4>{title}</h4>}</div>
			)}
		</>
	);
};

export default SectionHeader;
