import React from 'react';
import { useTranslation } from 'react-i18next';

function Footer() {
	const { t } = useTranslation();
	return (
		<div id="disclaimer" className="g81-footer">
			<figure className="text-end">{t('app.footer.disclaimer')}</figure>
		</div>
	);
}

export default Footer;
