import React from 'react';
import { useTranslation } from 'react-i18next';
import countryList from '../data/countries.json';
import { Country } from '../common/Types';

interface CountrySelectionProps {
	formName?: string;
	required?: boolean;
}

const CountrySelection: React.FC<CountrySelectionProps> = ({ formName, required }) => {
	const { t } = useTranslation(['translation', 'countries']);

	const getSortedList = () => {
		const modifiedData = countryList.map((country: Country) => {
			return {
				...country,
				name: t(country.code.toLocaleLowerCase() + '.name', { ns: 'countries' }),
			};
		});

		return modifiedData.sort((a, b) => a.name.localeCompare(b.name));
	};

	return (
		<select
			name={formName}
			id="countrySelection"
			className="form-select"
			aria-label={t('general.countryInitialSelection')}
			required={required ? true : false}
		>
			<option selected disabled value="-1">
				{t('general.countryInitialSelection')}
			</option>
			{getSortedList().map((country) => (
				<option value={country.code} key={country.code}>
					{country.name}
				</option>
			))}
		</select>
	);
};

export default CountrySelection;
