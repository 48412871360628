import React from 'react';
import Header from '../../components/Header';
import { useTranslation } from 'react-i18next';
import SourceReference from '../../components/SourceReference';

function History() {
	const { t, i18n } = useTranslation();
	const currentLanguage = i18n.language;
	const textDE = (
		<div>
			<h4>Nicht der erste Touring aus dem Hause M</h4>
			<p>
				Der G81 ist nicht der erste Touring aus dem Hause M, auch nicht der erste M3 Touring, jedoch der erste
				M3 Touring, der in Serie gegangen ist. Endlich hat das Warten ein Ende, und es gibt einen offiziellen
				M3 Touring. Aber wie kam es dazu?
				<br />
				Während mit den bekannten M5 Touring Modellen, auch bekannt als E34 (1992-1995) und E61 (2007-2010),
				bereits zwei Serienmodelle im Rahmen der 5er Reihe als M5 Touring gebaut wurden, gab es einen M3
				Touring in der E46 Reihe - jedoch nur als Konzept und Einzelstück. (Es gibt natürlich auch diverse
				Projekte von Privatpersonen, die sich ihren Traum von einem M3 Touring in Eigenregie erfüllt haben,
				aber das sind jeweils Einzelstücke.) Der E46 Touring ging niemals in Serie, da mit dem E46 in der
				Karosserieform eines Coupés die Basis nicht ohne größere Aufwände als Touring hätte umgesetzt werden
				können. Die Änderungen hätten sich bis in den Rohbau der Karosserie gezogen, und damit wurde die
				Entscheidung gegen eine Serienumsetzung getroffen.
				<br />
				Nun, ca. 20 Jahre später, ist er endlich da, der M3 Touring! Offiziell beim Händler bestellbar. Kein
				Sondermodell, das nur auf wenige Stückzahlen limitiert ist, und natürlich mit einem
				Reihensechszylinder.
			</p>
			<h4>G80+G21 = G81</h4>
			<p>
				In den verschneiten Weiten Schwedens, im Winter 2019, begann ein neues Kapitel in der
				BMW-M-Geschichte. Während einer nächtlichen Debriefing-Session nach intensiven Testfahrten des G80,
				entfachte sich eine Vision: die Geburt des G81, des ersten M3 Touring. In jener Nacht wurde erkannt,
				dass die G8x-Reihe weit mehr Potential birgt, als bisher ausgeschöpft. Die Entscheidung, den Traum
				eines M3 Touring Realität werden zu lassen, folgte prompt. Anders als beim E46, wo technische
				Einschränkungen die Serienproduktion eines Touring verhinderten, bot der G80 als Limousine die
				perfekte Plattform für diese ambitionierte Mission. Mit ihren vielfältigen Antriebskonzepten und
				Leistungsstufen lieferte die Reihe die ideale Leinwand, um den M3 Touring mit präziser Dynamik und
				unverwechselbarer Ästhetik zu gestalten.
				<br />
				Die Herausforderung lag in der Integration der robusten DNA eines M3 mit der vielseitigen
				Funktionalität eines Tourings. Ein besonderes Augenmerk galt dem Heckbereich – eine Arena technischer
				Raffinesse. Die Ingenieure standen vor der Aufgabe, Fahrwerk, Karosserie und Heckklappe nicht nur
				optisch, sondern auch in puncto Stabilität und Performance zu optimieren. Trotz der komplexen
				Anforderungen blieb die Liste der benötigten Neuteile erstaunlich überschaubar. Mit dem M3 Touring
				gelang es BMW, die Grenzen zwischen rasanter Sportlichkeit und alltäglicher Praktikabilität neu zu
				definieren.
			</p>
			<h4>Coffee to “Go”</h4>
			<p>
				Nachdem die Idee gereift war und ein klares Konzept vorlag, entschied man sich, über eine bloße
				virtuelle Idee hinauszugehen und einen greifbaren Prototypen zu bauen - etwas Konkretes, das den
				Entscheidungsträgern für die Serienproduktion praktisch keine andere Wahl ließ, als zuzustimmen. Ein
				echter 'Meinungsbildner'.
				<br />
				Eine Einladung zum Kaffee mit den "Machern" des Unternehmens wurde arrangiert, um diesen
				entscheidenden Schritt voranzutreiben. Nachdem die Idee vorgestellt worden war, gingen die Beteiligten
				die nächsten Schritte an. Dazu gehörte die Beschaffung eines Versuchsträgers in Form einer G80 M3
				Limousine, die bereits ihren Dienst erfüllt hatte, sowie eines 'normalen' 3er Tourings aus der
				aktuellen Baureihe G21.
			</p>
			<h4>Die 80/20 Regel - zum Ende hin wird’s aufwendig</h4>
			<p>
				Im ersten Schritt wurden die beiden Fahrzeuge komplett zerlegt, um ein genaueres Verständnis dafür zu
				entwickeln, wie eine Fusion beider Modelle zu einem M3 Touring führen könnte. Die Umsetzung der
				Frontpartie gestaltete sich relativ einfach, da hier auf die bewährten Methoden und Erfahrungen der M3
				Limousine zurückgegriffen werden konnte. Richtung Heck wurde es komplizierter: Zwar konnten die
				vorderen und hinteren Türen der M3 Limousine übernommen werden, doch ab der C-Säule stiegen die
				Herausforderungen signifikant. Diese resultierten nicht nur aus der optischen Verbreiterung der
				Kotflügel im Vergleich zum G21, sondern auch aus der charakteristischen breiteren Spurbreite der
				M-Modelle. Die Aufgabe bestand nun darin, Antriebsstrang und die breitere Hinterachse, inklusive der
				notwendigen Verstrebungen, am Unterboden des Tourings unterzubringen. Ein spezielles Feature, das nur
				bei einem Touring zu finden ist, ist die Kombination aus Heckstoßstange und Heckklappe. Hierfür wurde
				in akribischer Feinarbeit eine Mischung aus der M3 Stoßstange der Limousine und dem G21 angefertigt,
				um dem M3 Touring sowohl funktionell als auch optisch eine ansprechende Heckansicht zu verleihen.
				Abschließend wurde das Fahrzeug gespachtelt, grundiert und lackiert, um den perfekten Gesamteindruck
				für das G81 Showcar zu erzielen.
			</p>
			<h4>Das U-Boot taucht auf - aus Bielefeld</h4>
			<p>
				Anfangs nur einem kleinen Kreis Eingeweihter bekannt und als geheimes 'U-Boot'-Projekt gestartet,
				wurde das Showcar schließlich den führenden Köpfen der M GmbH und der BMW AG präsentiert. Nachdem die
				nötige Überzeugungsarbeit geleistet war und eine positive Entscheidung hinsichtlich der
				Produktisierung gefallen war, erhielt das Team die offizielle Genehmigung, das Fahrzeug als 'M3
				Touring' mit dem Projektcode 'G81' zu bezeichnen. Zuvor war es innerhalb der Projektgruppe, die an der
				initialen Idee und dem Showcar gearbeitet hatte, unter dem Codenamen 'Bielefeld' bekannt.
				<br />
				Der M3 Touring war bis dahin lediglich als Prototyp und Showcar geplant, doch mit der Entscheidung,
				das Projekt offiziell zu starten, kamen neue Herausforderungen auf die Mitarbeiter zu. Dazu zählten
				die Festlegung von Zeitplänen, die Klärung von Aufwänden und Aufgaben für die Serienumsetzung, die
				Frage der Marktanerkennung durch die Kunden sowie natürlich die Kosten. Am Ende zahlte sich jedoch die
				harte Überzeugungsarbeit und der Einsatz aller Beteiligten aus.
			</p>
			<h4>Erst der Anfang</h4>
			<p>
				Die Vorarbeit zahlte sich aus, und das Projekt erhielt grünes Licht. Damit begann für andere Teams die
				eigentliche Arbeit: Karosserie, Interieur, Elektrik, Elektronik, Motor, Getriebe und Fahrwerk mussten
				entwickelt und perfektioniert werden. Gleichzeitig war die Qualitätskontrolle und die Integration in
				die Serienproduktion im Werk, einschließlich der notwendigen Anpassungen in den Produktionsprozessen
				und der Entwicklung und Herstellung spezieller Werkzeuge, von entscheidender Bedeutung. Neben diesen
				technischen Aspekten gab es noch weitere Faktoren, die für ein M-Modell entscheidend sind, um
				potenziellen Kunden ein authentisches Gefühl für das Fahrzeug zu vermitteln. Hierzu zählten nicht nur
				Rundenzeiten und potenzielle Rekorde, sondern auch das Fahrgefühl, die Fahrdynamik und die
				Erprobungsfahrten zur Feinabstimmung des Fahrzeugs hinsichtlich Gewicht, Gewichtsverteilung und
				weiterer spezifischer Merkmale.
			</p>
			<p>
				<i>
					Dieser Text ist eine Zusammenfassung in eigenen Worten und ist nach besten Wissen und Gewissen
					erstellt worden.
				</i>
			</p>
		</div>
	);
	const textEN = (
		<div>
			<h4>Not the first Touring from the M division</h4>
			<p>
				The G81 is not the first touring car from the M division, nor is it the first M3 Touring, but it is
				the first M3 Touring to go into mass production. The wait is finally over, and there's now an official
				M3 Touring. But how did it come to be?
				<br />
				While there were already two series models in the 5 Series as M5 Tourings, known as E34 (1992-1995)
				and E61 (2007-2010), there was an M3 Touring in the E46 series - but only as a concept and a one-off.
				(Of course, there are also various projects by individuals who have realized their dream of an M3
				Touring on their own, but these are also one-offs.) The E46 Touring never went into mass production
				because the E46, in its coupe body style, could not have been converted into a touring without
				significant effort. The changes would have extended to the bare chassis of the body, and thus the
				decision was made against a production version.
				<br />
				Now, about 20 years later, the M3 Touring is finally here! Officially available for order from
				dealers. Not a special model limited to a few units, and of course, with an inline-six cylinder
				engine.
			</p>
			<h4>G80+G21 = G81</h4>
			<p>
				In the snowy expanses of Sweden, in the winter of 2019, a new chapter in BMW M history began. During a
				nocturnal debriefing session after intensive testing of the G80, a vision was ignited: the birth of
				the G81, the first M3 Touring. That night, it was recognized that the G8x series harbored far more
				potential than had been tapped into thus far. The decision to make the dream of an M3 Touring a
				reality was made promptly. Unlike with the E46, where technical limitations prevented the mass
				production of a Touring, the G80 sedan offered the perfect platform for this ambitious mission. With
				its diverse drive concepts and performance levels, the series provided the ideal canvas to design the
				M3 Touring with precise dynamics and distinctive aesthetics.
				<br />
				The challenge lay in integrating the robust DNA of an M3 with the versatile functionality of a
				Touring. Special attention was given to the rear area—a technical finesse arena. The engineers faced
				the task of optimizing the suspension, body, and tailgate not just visually, but also in terms of
				stability and performance. Despite the complex requirements, the list of needed new parts remained
				surprisingly manageable. With the M3 Touring, BMW succeeded in redefining the boundaries between rapid
				sportiness and everyday practicality.
			</p>
			<h4>Coffee to “Go”</h4>
			<p>
				After the idea had matured and a clear concept was in place, it was decided to go beyond a mere
				virtual idea and build a tangible prototype - something concrete that practically left the
				decision-makers for mass production no other choice but to agree. A real 'opinion maker'.
				<br />
				An invitation for coffee with the "doers" of the company was arranged to push forward this crucial
				step. After the idea had been presented, the participants proceeded with the next steps. This included
				acquiring a test vehicle in the form of a G80 M3 sedan that had already served its purpose, as well as
				a 'normal' 3 Series Touring from the current G21 series.
			</p>
			<h4>The 80/20 rule - it gets costly towards the end</h4>
			<p>
				In the first step, both vehicles were completely dismantled to develop a more precise understanding of
				how a fusion of both models could lead to an M3 Touring. Implementing the front section was relatively
				straightforward, as it was possible to rely on the proven methods and experiences of the M3 sedan.
				Towards the rear, it became more complicated: while the front and rear doors of the M3 sedan could be
				used, from the C-pillar onwards, the challenges increased significantly. These challenges resulted not
				only from the visual widening of the fenders compared to the G21 but also from the characteristic
				wider track width of the M models. The task now was to accommodate the drivetrain and the wider rear
				axle, including the necessary bracing, on the underbody of the Touring. A special feature found only
				in a Touring is the combination of the rear bumper and tailgate. For this, a mix of the M3 sedan's
				bumper and the G21 was meticulously crafted to give the M3 Touring an appealing rear view, both
				functionally and visually. Finally, the vehicle was filled, primed, and painted to achieve the perfect
				overall impression for the G81 show car.
			</p>
			<h4>The submarine surfaces - from Bielefeld</h4>
			<p>
				Initially known only to a small circle of insiders and started as a secret 'submarine' project, the
				show car was eventually presented to the leading heads of M GmbH and BMW AG. After the necessary
				persuasion was carried out and a positive decision regarding productization had been made, the team
				received official permission to designate the vehicle as 'M3 Touring' with the project code 'G81'.
				Previously, it was known within the project group that had worked on the initial idea and the show car
				under the codename 'Bielefeld'.
				<br />
				Until then, the M3 Touring had only been planned as a prototype and show car, but with the decision to
				officially start the project, new challenges arose for the staff. These included setting timelines,
				clarifying efforts and tasks for series implementation, the question of market recognition by
				customers, and of course, costs. In the end, however, the hard work of persuasion and the commitment
				of all those involved paid off.
			</p>
			<h4>Just the beginning</h4>
			<p>
				The preliminary work paid off, and the project received the green light. This marked the beginning of
				the actual work for other teams: body, interior, electrics, electronics, engine, transmission, and
				chassis had to be developed and perfected. At the same time, quality control and integration into mass
				production at the plant, including the necessary adjustments in the production processes and the
				development and manufacturing of special tools, were of crucial importance. In addition to these
				technical aspects, there were other factors critical to an M model to convey an authentic feel of the
				vehicle to potential customers. These included not only lap times and potential records but also the
				driving feel, driving dynamics, and test drives for fine-tuning the vehicle in terms of weight, weight
				distribution, and other specific characteristics.
			</p>
			<p>
				<i>
					This text is a summary in my own words and has been created to the best of my knowledge and belief.
				</i>
			</p>
		</div>
	);
	return (
		<div id="history" className="g81-page">
			<div className="row">
				<Header title={t('page.history.header')} />
			</div>
			<div className="row">
				<div className="col">
					{currentLanguage === 'en' && textEN}
					{currentLanguage === 'de' && textDE}
				</div>
			</div>
			<SourceReference
				source="YouTube BMW M - M3 Touring Playlist"
				dateAccessed="2023-12-23"
				url="https://www.youtube.com/playlist?list=PLuXwAhsNhc_G9zU_lmAmaaF_HPAIdemJo"
			/>
		</div>
	);
}

export default History;
