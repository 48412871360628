import { useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Utils } from './Utils';
import { useTranslation } from 'react-i18next';

const withLanguageFallback = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
	const LanguageFallbackComponent: React.FC<P> = (props) => {
		const { i18n } = useTranslation();
		const navigate = useNavigate();
		const { pathname } = useLocation();

		const changeLanguage = useCallback(
			(newLang: string) => {
				const newPathname = pathname.replace(/^\/[^/]+/, `/${newLang}`);
				navigate(newPathname);
				i18n.changeLanguage(newLang);
			},
			[i18n, navigate, pathname]
		);

		useEffect(() => {
			const pathParts = pathname.split('/');
			const lang = pathParts[1] || Utils.getDefaultLang();

			if (!Utils.getSupportedLanguages().includes(lang)) {
				changeLanguage(Utils.getDefaultLang());
			}
		}, [pathname, changeLanguage]); // Include changeLanguage in the dependencies array

		return <WrappedComponent {...props} />;
	};

	return LanguageFallbackComponent;
};

export default withLanguageFallback;
