import React from 'react';
import Header from '../../components/Header';
import { useTranslation } from 'react-i18next';

function Imprint() {
	const { t, i18n } = useTranslation();
	const currentLanguage = i18n.language;
	const textDE = (
		<>
			<p>
				Diese Seite ist privat und benötigt daher kein Impressum.
				<br />
				Alle Daten und Medien sind öffentlich im Internet zugänglich und mit den jeweiligen Quellen
				ausgezeichnet. Es wird keine Garantie für Richtigkeit und Vollständigkeit übernommen.
			</p>
			<p>
				BMW, BMW M, M3, M Performance sind eingetragene Marken der BMW Group, BMW AG oder BMW M GmbH. Alle
				Rechte vorbehalten. Die genannten Marken sind Eigentum der jeweiligen Hersteller und Vertriebsfirmen.
				Diese Seite steht in keinerlei Verbindung mit diesen Unternehmen. Die Markennamen werden
				ausschließlich zur besseren Identifizierung der Produkte für die Besucher dieser Seite verwendet. Die
				Hersteller haften nicht für den Inhalt dieser Seite.
			</p>
		</>
	);
	const textEN = (
		<>
			<p>
				This page is private and therefore does not require an imprint.
				<br />
				All data and media are publicly accessible on the internet and labeled with their respective sources.
				No guarantee is provided for accuracy and completeness.
			</p>
			<p>
				BMW, BMW M, M3, M Performance are registered trademarks of the BMW Group, BMW AG, or BMW M GmbH. All
				rights reserved. All mentioned trademarks belong to their respective manufacturers and distributors.
				This page has no affiliation with these companies. The brand names are mentioned solely to better
				identify the products for visitors to this page. The manufacturers are neither associated with nor
				liable for the content of this page.
			</p>
		</>
	);
	return (
		<div id="imprint" className="g81-page">
			<div className="row">
				<Header title={t('page.imprint.header')} />
			</div>
			<div className="row">
				<div className="col">
					{currentLanguage === 'en' && textEN}
					{currentLanguage === 'de' && textDE}
				</div>
			</div>
		</div>
	);
}

export default Imprint;
