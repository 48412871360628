import React from 'react';
import partsData from '../../data/mPerformanceParts.json';
import rimsData from '../../data/rims.json';
import Header from '../../components/Header';
import { useTranslation } from 'react-i18next';
import SourceReference from '../../components/SourceReference';
import SectionHeader from '../../components/SectionHeader';

function Parts() {
	const { t } = useTranslation(['translation', 'parts']);
	const getTextForParts = (key: string): string => {
		return t(key, { ns: 'parts' });
	};
	return (
		<div id="parts-m-performance" className="g81-page">
			<div className="row">
				<Header title={getTextForParts('header')} />
			</div>
			<div className="row">
				<div className="col mb-5">{getTextForParts('intro')}</div>
			</div>
			<div className="row">
				<div className="col mb-5">
					<SectionHeader title={getTextForParts('section.mPerformance.header')} />
					<table className="table table-striped">
						<thead className="table-light">
							<tr>
								<th>{getTextForParts('section.mPerformance.headerName')}</th>
								<th>{getTextForParts('section.mPerformance.headerPartNumber')}</th>
								<th>{getTextForParts('section.mPerformance.headerRequiredAmount')}</th>
								<th>{getTextForParts('section.mPerformance.headerSide')}</th>
								<th>{getTextForParts('section.mPerformance.headerMaterials')}</th>
							</tr>
						</thead>
						<tbody>
							{Object.entries(partsData).map(([key, value]) => {
								const isMultiItemFirst = value.totalRequired === 2 && value.itemNo === 1;
								const isMultiItemSecond = value.totalRequired === 2 && value.itemNo === 2;
								return (
									<tr key={key}>
										{isMultiItemFirst && !isMultiItemSecond && (
											<td rowSpan={2}>{getTextForParts('partNames.' + value.partNo)}</td>
										)}
										{!isMultiItemFirst && !isMultiItemSecond && (
											<td>{getTextForParts('partNames.' + value.partNo)}</td>
										)}
										<td>{value.partNo}</td>
										<td>{value.requiredAmount}</td>
										<td>{t('general.' + value.side)}</td>
										<td>
											{value.materials
												.map((materialKey) => getTextForParts('materials.' + materialKey))
												.join(', ')}
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<SectionHeader title={getTextForParts('section.rims.header')} />
					<table className="table table-striped">
						<thead className="table-light">
							<tr>
								<th>{getTextForParts('section.rims.headerManufacturerOem')}</th>
								<th>{getTextForParts('section.rims.headerModelName')}</th>
								<th>{getTextForParts('section.rims.headerFinish')}</th>
								<th>{getTextForParts('section.rims.headerPartNumber')}</th>
								<th>{getTextForParts('section.rims.headerDiameter')}</th>
								<th>{getTextForParts('section.rims.headerWidth')}</th>
								<th>{getTextForParts('section.rims.headerOffset')}</th>
								<th>{getTextForParts('section.rims.headerContour')}</th>
								<th>{getTextForParts('section.rims.headerFitment')}</th>
								<th>{getTextForParts('section.rims.headerInfo')}</th>
								<th>{getTextForParts('section.rims.headerManufacturedBy')}</th>
								<th>{getTextForParts('section.rims.headerManufacturerPartNr')}</th>
							</tr>
						</thead>
						<tbody>
							{rimsData.map((rim, index) => {
								const isEvenIndex = index % 2 === 0;
								return (
									<tr key={index}>
										{isEvenIndex && (
											<>
												<td rowSpan={2}>{rim.manufacturerOem}</td>
												<td rowSpan={2}>{rim.modelName}</td>
												<td rowSpan={2}>{rim.finish}</td>
											</>
										)}
										<td>{rim.partNumber}</td>
										<td>{rim.diameterInch}"</td>
										<td>{rim.widthInch}"</td>
										<td>{rim.offset}</td>
										<td>{rim.contour}</td>
										<td>{t('general.' + rim.fitment)}</td>
										<td>{rim.info.map((infoKey) => t('general.' + infoKey)).join(', ')}</td>
										<td>{rim.manufacturedBy}</td>
										<td>{rim.manufacturerPartNumber}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>
			<hr />
			<div className="row">
				<div className="col">
					<SourceReference
						source="BMW M"
						dateAccessed="2023-12-23"
						url="https://www.bmw-m.com/de/topics/magazine-article-pool/m-performance-parts-bmw-m3-touring.html"
					/>
					<SourceReference
						source="BMW Original Zubehör Shop"
						dateAccessed="2023-12-23"
						url="https://www.bmw.de/de/shop/ls/cp/physical-goods/de-BF_ACCESSORY/de-SH1_MPERFORMANCE?page=1"
					/>
					<SourceReference
						source="Leebmann24.de"
						dateAccessed="2023-12-25"
						url="https://www.leebmann24.com/de/de"
					/>
					<SourceReference
						source="Baum-BMWshop24.de"
						dateAccessed="2023-12-25"
						url="https://www.baum-bmwshop24.de/"
					/>
					<SourceReference
						source="PaddleShifterz PS"
						dateAccessed="2024-04-29"
						url="https://paddleshifterz.com/"
					/>
				</div>
			</div>
		</div>
	);
}

export default Parts;
