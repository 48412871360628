import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import 'moment/locale/de';

interface LanguageSelectionProps {
	items: string[];
}

const LanguageSelection: React.FC<LanguageSelectionProps> = ({ items }) => {
	const { t, i18n } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();

	const changeLanguage = (newLang: string) => {
		const pathname = location.pathname.replace(/^\/[^/]+/, `/${newLang}`);
		navigate(pathname);
		i18n.changeLanguage(newLang);
	};

	const renderLanguageSelection = (languages: string[]) => {
		return (
			<div className="dropdown mx-3">
				<button
					className="btn btn-sm btn-light dropdown-toggle"
					type="button"
					id="languageDropdownButton"
					data-bs-toggle="dropdown"
					aria-expanded="false"
				>
					{t('general.language')}
				</button>
				<ul className="dropdown-menu" aria-labelledby="languageDropdownButton">
					{languages.map((language) => (
						<li key={language} onClick={() => changeLanguage(language)}>
							<button className="dropdown-item" type="button">
								{t(`languages.${language}`)}
							</button>
						</li>
					))}
				</ul>
			</div>
		);
	};

	return <div>{renderLanguageSelection(items)}</div>;
};

export default LanguageSelection;
