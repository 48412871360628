import React from 'react';
import { useTranslation } from 'react-i18next';

interface MonthYearSelectionProps {
	formNameMonth?: string;
	formNameYear?: string;
	required?: boolean;
}

const MonthYearSelection: React.FC<MonthYearSelectionProps> = ({ formNameMonth, formNameYear, required }) => {
	const { t } = useTranslation();

	return (
		<div className="input-group">
			<select name={formNameMonth} className="form-select" id="monthSelection">
				<option selected disabled value="-1">
					{t('monthYear.month')}
				</option>
				<option value="1">{t('monthYear.01-jan')}</option>
				<option value="2">{t('monthYear.02-feb')}</option>
				<option value="3">{t('monthYear.03-mar')}</option>
				<option value="4">{t('monthYear.04-apr')}</option>
				<option value="5">{t('monthYear.05-may')}</option>
				<option value="6">{t('monthYear.06-jun')}</option>
				<option value="7">{t('monthYear.07-jul')}</option>
				<option value="8">{t('monthYear.08-aug')}</option>
				<option value="9">{t('monthYear.09-sep')}</option>
				<option value="10">{t('monthYear.10-oct')}</option>
				<option value="11">{t('monthYear.11-nov')}</option>
				<option value="12">{t('monthYear.12-dec')}</option>
			</select>
			<span className="input-group-text">/</span>
			<select name={formNameYear} className="form-select" id="yearSelection">
				<option selected disabled value="-1">
					{t('monthYear.year')}
				</option>
				<option value="2022">2022</option>
				<option value="2023">2023</option>
				<option value="2024">2024</option>
			</select>
		</div>
	);
};

export default MonthYearSelection;
