import React, { useEffect, useState } from 'react';
import { RegisterEntry } from '../../common/Types';
import Header from '../../components/Header';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../common/Utils';

function RegistryList() {
	const { t } = useTranslation(['translation', 'colors', 'countries', 'registry']);
	const getTextForRegistry = (key: string): string => {
		return t(key, { ns: 'registry' });
	};
	const getTextForColors = (key: string): string => {
		return t(key, { ns: 'colors' });
	};
	const getTextForCountries = (key: string): string => {
		return t(key, { ns: 'countries' });
	};
	const [showError, setShowError] = useState(false);
	const [entries, setEntries] = useState<RegisterEntry[]>([]);

	useEffect(() => {
		const fetchData = () => {
			const currentDomain = window.location.origin;
			// Perform fetch request
			fetch(`${currentDomain}/scripts/registry.php`)
				.then((response) => response.json())
				.then((jsonData) => {
					const newEntries = jsonData.data.map((entry: any, index: number) => ({
						productionNumber: index,
						userName: entry.userName,
						socialmedia: Utils.getSocialMediaUrl(entry.socialMediaPlatform, entry.socialMediaAccount),
						country: Utils.getCountryByCode(entry.countryOfResidence),
						color: Utils.getColorByCode(entry.colorCode),
					}));
					setEntries(newEntries);
				})
				.catch((error) => {
					setShowError(true);
				});
		};

		fetchData();
	}, []);

	return (
		<div id="registryList" className="g81-page">
			<div className="row">
				<Header title={getTextForRegistry('header')} />
			</div>
			<div className="row">
				<div className="col">
					<table className="table table-striped">
						<thead className="table-light">
							<tr>
								{/*<th>{getTextForRegistry('table.headerNumber')}</th>*/}
								<th>{getTextForRegistry('table.headerName')}</th>
								<th>{getTextForRegistry('table.headerColor')}</th>
								<th>{getTextForRegistry('table.headerSocialMedia')}</th>
								<th>{getTextForRegistry('table.headerCountry')}</th>
							</tr>
						</thead>
						<tbody>
							{entries.map((item, idx) => (
								<tr key={idx}>
									{/*<td>{item.productionNumber}</td>*/}
									<td>{item.userName}</td>
									<td>
										{getTextForColors(item.color.code)} ({item.color.code})
									</td>
									<td>
										<a target="_blank" rel="noopener noreferrer" href={`${item.socialmedia}`}>
											{item.socialmedia}
										</a>
									</td>
									<td>{getTextForCountries(item.country.code.toLocaleLowerCase() + '.name')}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
}

export default RegistryList;
