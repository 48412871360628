import React from 'react';
import { useTranslation } from 'react-i18next';

interface AlertMessageProps {
	isError: boolean;
	titleKey: string;
	messageKey: string;
}

const AlertMessage: React.FC<AlertMessageProps> = ({ isError, titleKey, messageKey }) => {
	const { t } = useTranslation(['translation', 'contact']);
	const getTextForContact = (key: string): string => {
		return t(key, { ns: 'contact' });
	};
	if (isError !== true) {
		return (
			<div key="alertMessage_success" className="alert alert-success" role="alert">
				<h4 className="alert-heading">
					<i className="bi check-circle"></i>
					{getTextForContact(titleKey)}
				</h4>
				<p>{getTextForContact(messageKey)}</p>
			</div>
		);
	} else {
		return (
			<div key="alertMessage_error" className="alert alert-danger" role="alert">
				<h4 className="alert-heading">
					<i className="bi x-circle"></i>
					{getTextForContact(titleKey)}
				</h4>
				<p>{getTextForContact(messageKey)}</p>
			</div>
		);
	}
};

export default AlertMessage;
