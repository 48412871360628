import React from 'react';
import { useNavigate } from 'react-router-dom';

const HomeLink = () => {
	const navigate = useNavigate();

	const navigateToHomePage = () => {
		// Extract the current language from the pathname
		const currentLanguage = window.location.pathname.split('/')[1]; // Assuming the language is the first part of the path

		// Navigate to the homepage in the current language
		navigate(`/${currentLanguage}/home`); // Adjust 'home' to your homepage route
	};

	return <div className="g81-logo-homelink" onClick={navigateToHomePage} aria-hidden="true" />;
};

export default HomeLink;
