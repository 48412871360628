import React from 'react';
import { useTranslation } from 'react-i18next';

interface StatsCardProps {
	titleKey: string;
	value: string;
	icon: string;
	footerValue?: string;
	footerKey?: string;
}

const StatsCard: React.FC<StatsCardProps> = ({ titleKey, value, icon, footerValue, footerKey }) => {
	const { t } = useTranslation();

	const renderFooter = (footerValue: string, footerKey: string) => {
		return (
			<p className="mt-3 mb-0 text-muted text-sm">
				<span className="text-success mr-2">
					<i className="fa fa-arrow-up"></i>
					{footerValue}
				</span>
				<span className="text-nowrap">{t(footerKey)}</span>
			</p>
		);
	};
	return (
		<div className="card card-stats mb-4 mb-xl-0">
			<div className="card-body">
				<div className="row">
					<div className="col">
						<h5 className="card-title text-uppercase text-muted mb-0">{t(titleKey)}</h5>
						<span className="h2 font-weight-bold mb-0">{value}</span>
					</div>
					<div className="col-auto">
						<div className="g81-icon g81-icon-shape bg-info text-white g81-stats-card-icon-round shadow">
							<i className={'bi ' + icon}></i>
						</div>
					</div>
				</div>
				{footerValue && footerKey && renderFooter(footerValue, footerKey)}
			</div>
		</div>
	);
};

export default StatsCard;
