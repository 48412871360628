import { DestinationType } from './Types';
import {
	faLandmark,
	faBinoculars,
	faMonument,
	faIndustry,
	faMountainSun,
	faFlagCheckered,
} from '@fortawesome/free-solid-svg-icons';

export class MapsUtils {
	private static typeMap = {
		museum: {
			background: 'ffaf00',
			borderColor: '#fff',
			glyphColor: 'white',
			iconPath: faLandmark.icon[4] as string,
		},
		historical: {
			background: '#cf5d00',
			borderColor: '#fff',
			glyphColor: 'white',
			iconPath: faMonument.icon[4] as string,
		},
		manufacturer: {
			background: '#ac1333',
			borderColor: '#000',
			glyphColor: 'white',
			iconPath: faIndustry.icon[4] as string,
		},
		scenicRoad: {
			background: '#6ad5ee',
			borderColor: '#fff',
			glyphColor: 'white',
			iconPath: faBinoculars.icon[4] as string,
		},
		mountainPass: {
			background: '#0181b5',
			borderColor: '#fff',
			glyphColor: 'white',
			iconPath: faMountainSun.icon[4] as string,
		},
		raceTrack: {
			background: '#2d353d',
			borderColor: '#fff',
			glyphColor: 'white',
			iconPath: faFlagCheckered.icon[4] as string,
		},
	};

	public static getPinSvgFontawesome(destinationType: DestinationType): google.maps.Symbol {
		return {
			path: this.typeMap[destinationType].iconPath,
			fillColor: this.typeMap[destinationType].background,
			fillOpacity: 1,
			strokeWeight: 1.25,
			strokeColor: this.typeMap[destinationType].borderColor,
			scale: 0.045,
			anchor: new google.maps.Point(0, 20),
		};
	}
}
