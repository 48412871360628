import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.use(
		resourcesToBackend((language, namespace, callback) => {
			import(`../locales/${language}/${namespace}.json`)
				.then((resources) => {
					callback(null, resources);
				})
				.catch((error) => {
					console.log(error);
					callback(error, null);
				});
		})
	)
	.init({
		fallbackLng: 'en', // Default language if detection fails
		keySeparator: '.', // Separator in your JSON files (e.g., { "key": "value" })
		interpolation: {
			escapeValue: false, // React already escapes strings
		},
		detection: {
			order: ['path', 'navigator'],
			lookupQuerystring: 'lang',
		},
		supportedLngs: ['en', 'de'],
		debug: true,
	});

const normalizeLanguage = (language: string) => {
	if (language && language.length > 2) {
		return language.slice(0, 2);
	}
	return language;
};

const pathLanguage = window.location.pathname.split('/')[1];
const languageToSet = normalizeLanguage(pathLanguage);

i18n.changeLanguage(languageToSet);

export default i18n;
