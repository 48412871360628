import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Utils } from '../common/Utils';

interface SocialMediaSelectionProps {
	formNamePlatform: string;
	formNameAccount: string;
	required?: boolean;
}

const SocialMediaSelection: React.FC<SocialMediaSelectionProps> = ({
	formNamePlatform,
	formNameAccount,
	required,
}) => {
	const { t } = useTranslation();
	const [platform, setPlatform] = useState<string>('');
	const [accountName, setAccountName] = useState<string>('');
	const [socialMediaUrl, setSocialMediaUrl] = useState<string>('');

	const handlePlatformChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setPlatform(event.target.value);
		updateSocialMediaUrl();
	};

	const handleAccountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setAccountName(event.target.value);
		updateSocialMediaUrl();
	};

	const updateSocialMediaUrl = () => {
		const platformType = Utils.getSocialMediaPlatform(platform);
		if (platformType) {
			const url = Utils.getSocialMediaUrl(platformType, accountName);
			if (url) {
				setSocialMediaUrl(url);
				return;
			}
		} else if (!platformType && socialMediaUrl.length > 0) {
			setSocialMediaUrl('');
		}
	};

	const isValid = platform && accountName;

	return (
		<div>
			<div className="input-group mb-3">
				<select
					name={formNamePlatform}
					id="socialMediaSelection"
					className="form-select"
					onChange={handlePlatformChange}
					aria-label={t('socialMedia.platform')}
					required={required ? true : false}
				>
					<option selected disabled value="-1">
						{t('socialMedia.platform')}
					</option>
					<option value="youtube" key="youtube">
						YouTube
					</option>
					<option value="instagram" key="instagram">
						Instagram
					</option>
					<option value="facebook" key="facebook">
						Facebook
					</option>
					<option value="twitter_x" key="twitter_x">
						{t('socialMedia.twitterAka')}
					</option>
				</select>
				<input
					name={formNameAccount}
					type="text"
					className="form-control"
					onChange={handleAccountChange}
					aria-label="Text input with segmented dropdown button"
				/>
			</div>
			<div className="form-text" id="basic-addon4">
				{isValid && (
					<div className="form-text" id="basic-addon4">
						{t('socialMedia.validateLabel')}:{' '}
						<a href={socialMediaUrl} target="_blank" rel="noreferrer">
							{socialMediaUrl}
						</a>
					</div>
				)}
			</div>
		</div>
	);
};

export default SocialMediaSelection;
