import React, { useState } from 'react';
import Header from '../../components/Header';
import { useTranslation } from 'react-i18next';
import StatsCard from '../../components/StatsCard';
import { StatsData } from '../../common/Types';

function Statistics() {
	const { t } = useTranslation(['translation', 'stats']);
	const initialState: StatsData = {
		numberOfRegisteredOwners: 0,
		numberOfRegisteredVehicles: 0,
		ownersByCountry: [],
		registrationsByYear: [],
		ownershipDuration: [],
		vehiclesByColorCode: [],
	};
	const [statsData, setStatsData] = useState<StatsData>(initialState);

	const convertCounts = (array: any[]) => array.map((item) => ({ ...item, count: Number(item.count) }));
	const parseStatsDataResponse = (data: any): StatsData => {
		let result = {} as StatsData;
		result.numberOfRegisteredOwners = Number(data.numberOfRegisteredOwners);
		result.numberOfRegisteredVehicles = Number(data.numberOfRegisteredVehicles);
		result.ownersByCountry = convertCounts(data.ownersByCountry);
		result.registrationsByYear = convertCounts(data.registrationsByYear);
		result.ownershipDuration = convertCounts(data.ownershipDuration);
		result.vehiclesByColorCode = convertCounts(data.vehiclesByColorCode);
		return result;
	};

	const fetchData = () => {
		const currentDomain = window.location.origin;
		fetch(`${currentDomain}/scripts/stats.php`)
			.then((response) => response.json())
			.then((data) => {
				setStatsData(parseStatsDataResponse(data));
			})
			.catch((error) => {
				console.error('Error while fetching stats:', error);
			});
	};
	React.useEffect(() => {});
	fetchData();
	return (
		<div id="faq" className="g81-page">
			<div className="row">
				<Header title={t('header', { ns: 'stats' })} />
			</div>
			<div className="row">
				<div className="col">
					<p>{t('info', { ns: 'stats' })}</p>
					<div className="row">
						<div className="col-xl-3 col-lg-6">
							<StatsCard
								titleKey={t('cards.ownersRegistered', { ns: 'stats' })}
								value={statsData.numberOfRegisteredOwners.toString()}
								icon="bi-person-badge"
							/>
						</div>
						<div className="col-xl-3 col-lg-6">
							<StatsCard
								titleKey={t('cards.vehiclesRegistered', { ns: 'stats' })}
								value={statsData.numberOfRegisteredVehicles.toString()}
								icon="bi-car-front"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Statistics;
