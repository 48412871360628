import React, { useRef, useState } from 'react';
import Header from '../../components/Header';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../common/Utils';
import AlertMessage from '../../components/AlertMessage';

interface ContactFormStatus {
	status: string;
	isError: boolean;
	responseReceived: boolean;
}

function Contact() {
	const { t } = useTranslation(['translation', 'contact']);
	const getTextForContact = (key: string): string => {
		return t(key, { ns: 'contact' });
	};
	const formRef = useRef<HTMLFormElement>(null);
	const [formStatus, setFormStatus] = useState<ContactFormStatus>({
		status: '',
		isError: false,
		responseReceived: false,
	});

	const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault(); // Prevent the default form submission

		const form = event.currentTarget;
		const inputName = form.querySelector('#inputName') as HTMLInputElement;
		const inputMail = form.querySelector('#inputMail') as HTMLInputElement;
		const inputMessage = form.querySelector('#inputMessage') as HTMLTextAreaElement;
		const consentAgree = form.querySelector('#consentAgree') as HTMLInputElement;

		if (inputName && inputMail && inputMessage && consentAgree) {
			// Check validity and handle custom validation
			if (
				inputName.value.trim() === '' ||
				inputMail.value.trim() === '' ||
				!Utils.isEmailValid(inputMail.value.trim()) ||
				inputMessage.value.trim() === '' ||
				!consentAgree.checked
			) {
				// Show validation errors
				form.classList.add('was-validated');
			} else {
				// If all validations pass, proceed with form submission
				form.classList.remove('was-validated');

				// Submit the form
				// Assuming your form's action attribute points to the PHP script
				//form.submit();
				await performSubmit(event);
			}
		}
	};

	const performSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		const formData = new FormData(event.currentTarget);

		try {
			const response = await fetch('/scripts/contact.php', {
				method: 'POST',
				body: formData,
			});

			const responseData = await response.json();
			setFormStatus({
				...formStatus,
				status: responseData.status,
				isError: responseData.status !== 'success',
				responseReceived: true,
			});
			resetFormFields();
		} catch (error) {
			setFormStatus({
				...formStatus,
				status: 'network_error',
				isError: true,
				responseReceived: true,
			});
		}
	};

	const resetFormFields = () => {
		if (formRef.current) {
			formRef.current.reset();
		}
	};

	return (
		<div id="privacy" className="g81-page">
			<div className="row">
				<Header title={getTextForContact('header')} />
			</div>
			<div className="row">
				<div className="col">
					<p>{getTextForContact('intro')}</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<form
						ref={formRef}
						className="mt-3 needs-validation"
						noValidate
						onSubmit={handleFormSubmit}
						action="./scripts/contact.php"
						method="POST"
					>
						<div className="row mb-3">
							<label htmlFor="inputName" className="col-sm-3 col-form-label">
								{getTextForContact('name')}
							</label>
							<div className="col-sm-5">
								<input
									name="name"
									type="text"
									className="form-control"
									id="inputName"
									placeholder={getTextForContact('namePlaceholder')}
									required
								/>
								<div className="valid-feedback">{getTextForContact('validationSuccessGeneric')}</div>
							</div>
						</div>
						<div className="row mb-3">
							<label htmlFor="inputMail" className="col-sm-3 col-form-label">
								{getTextForContact('email')}
							</label>
							<div className="col-sm-5">
								<input
									name="email"
									type="text"
									className="form-control"
									id="inputMail"
									placeholder={getTextForContact('emailPlaceholder')}
								/>
								<div className="valid-feedback">{getTextForContact('validationSuccessGeneric')}</div>
							</div>
							<div className="col-sm-4">
								<div id="nameHelp" className="form-text">
									{getTextForContact('emailHint')}
								</div>
							</div>
						</div>
						<div className="row mb-3">
							<label htmlFor="inputMessage" className="col-sm-3 col-form-label">
								{getTextForContact('message')}
							</label>
							<div className="col-sm-5">
								<textarea
									name="message"
									className="form-control"
									id="inputMessage"
									placeholder={getTextForContact('messagePlaceholder')}
									required
									rows={10}
								/>
								<div className="valid-feedback">{getTextForContact('validationSuccessGeneric')}</div>
							</div>
						</div>
						<div className="row mb-3">
							<div className="col-auto">
								<div className="form-check">
									<input
										name="consent"
										className="form-check-input"
										type="checkbox"
										value=""
										id="consentAgree"
										required
									/>
									<label className="form-check-label" htmlFor="consentAgree">
										{getTextForContact('consent')}
									</label>
									<div className="invalid-feedback">{getTextForContact('consentHint')}</div>
								</div>
							</div>
						</div>
						<div className="row mb-3">
							<div className="col-auto">
								<button className="btn btn-primary" type="submit">
									{getTextForContact('submit')}
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
			{formStatus.responseReceived && (
				<div className="row">
					<div className="col">
						{formStatus.isError ? (
							<AlertMessage isError={true} titleKey="alertErrorTitle" messageKey="alertErrorMessage" />
						) : (
							<AlertMessage isError={false} titleKey="alertSuccessTitle" messageKey="alertSuccessMessage" />
						)}
					</div>
				</div>
			)}
		</div>
	);
}

export default Contact;
