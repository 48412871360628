import React from 'react';
import Header from '../../components/Header';
import { useTranslation } from 'react-i18next';
import SourceReference from '../../components/SourceReference';
import vehicleRegistrationData from '../../data/vehicleRegistrations.json';
import baseListPriceData from '../../data/baseListPrices.json';
import { BaseListPrice } from '../../common/Types';
import SectionHeader from '../../components/SectionHeader';
const moment = require('moment');

function Facts() {
	const { t } = useTranslation(['translation', 'facts']);
	const getTextForFacts = (key: string): string => {
		return t(key, { ns: 'facts' });
	};

	const sortedBasePrices = baseListPriceData.sort((a, b) => {
		if (a.country < b.country) {
			return -1;
		}
		if (a.country > b.country) {
			return 1;
		}
		return 0;
	});

	// Extracting distinct dates
	const distinctDates = [
		...new Set(vehicleRegistrationData.flatMap((reg) => reg.registrations.map((r) => r.date))),
	];

	// Function to calculate sums for each date
	const calculateSumForDate = (date: string) => {
		return vehicleRegistrationData.reduce((total, reg) => {
			const registration = reg.registrations.find((r) => r.date === date);
			return total + (registration ? registration.amount : 0);
		}, 0);
	};

	const calculatePercentageIncrease = (newPrice: number, oldPrice: number) => {
		if (newPrice === 0 || oldPrice === 0) {
			return '-';
		}
		const increase = ((newPrice - oldPrice) / oldPrice) * 100;
		const sign = increase >= 0 ? '+' : '';
		return `${sign}${increase.toFixed(1)}%`;
	};

	return (
		<div id="m3Touring" className="g81-page">
			<div className="row">
				<Header title={getTextForFacts('header')} />
			</div>
			<div className="row">
				<div className="col mb-5">
					<SectionHeader title={getTextForFacts('section.general.header')} />
					<table className="table table-striped">
						<thead className="table-light">
							<tr>
								<th>Property</th>
								<th>Value</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>{getTextForFacts('section.general.hsntsn')}</td>
								<td>7909 / ADG</td>
							</tr>
							<tr>
								<td>{getTextForFacts('section.general.hsntsnLci')}</td>
								<td>- / -</td>
							</tr>
							<tr>
								<td>{getTextForFacts('section.general.typeApproval')}</td>
								<td>{moment('2022-07-27').format('L')}</td>
							</tr>
							<tr>
								<td>{getTextForFacts('section.general.modelCode')}</td>
								<td>G234M</td>
							</tr>
							<tr>
								<td>{getTextForFacts('section.general.modelVariant')}</td>
								<td>11GB</td>
							</tr>
							<tr>
								<td>{getTextForFacts('section.general.internalCodeName')}</td>
								<td>Bielefeld</td>
							</tr>
							<tr>
								<td>{getTextForFacts('section.general.internalModelCode')}</td>
								<td>G81</td>
							</tr>
							<tr>
								<td>{getTextForFacts('section.general.productionPlant')}</td>
								<td>{getTextForFacts('section.general.productionPlantLocation')}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div className="row">
				<div className="col mb-5">
					<SectionHeader title={getTextForFacts('section.typeApproval.header')} />
					<table className="table table-striped">
						<thead className="table-light">
							<tr>
								<th>{getTextForFacts('section.typeApproval.table.headerManufacturer')}</th>
								<th>{getTextForFacts('section.typeApproval.table.headerModelName')}</th>
								<th>{getTextForFacts('section.typeApproval.table.headerApprovalObject')}</th>
								<th>{getTextForFacts('section.typeApproval.table.headerTechnicalService')}</th>
								<th>{getTextForFacts('section.typeApproval.table.headerMemberStateEmissionApproval')}</th>
								<th>{getTextForFacts('section.typeApproval.table.headerTechnicalServiceEmissions')}</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>BMW M GmbH Gesellschaft</td>
								<td>G234M</td>
								<td>Fahrzeuge der Klasse M</td>
								<td>TÜV SÜD Auto Service GmbH</td>
								<td>e24</td>
								<td>TÜV SÜD Auto Service GmbH</td>
							</tr>
							<tr>
								<td colSpan={6}>
									<table className="table table-sm">
										<thead>
											<tr>
												<th>{getTextForFacts('section.typeApproval.detailTable.headerCommercialName')}</th>
												<th>
													{getTextForFacts('section.typeApproval.detailTable.headerTypeApprovalNumber')}
												</th>
												<th>{getTextForFacts('section.typeApproval.detailTable.headerProcedureType')}</th>
												<th>{getTextForFacts('section.typeApproval.detailTable.headerDateOfApproval')}</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>M3, M3 Competition, M4, M4 Competition</td>
												<td>e1*2018/858*00003*00</td>
												<td>{getTextForFacts('section.typeApproval.values.valueInitial')}</td>
												<td>{moment('2020-10-22').format('L')}</td>
											</tr>
											<tr>
												<td>M3, M3 Competition, M4, M4 Competition, M4 Competition M xDrive</td>
												<td>e1*2018/858*00003*01</td>
												<td>{getTextForFacts('section.typeApproval.values.valueExtension')}</td>
												<td>{moment('2021-06-09').format('L')}</td>
											</tr>
											<tr>
												<td>M3, M3 Competition, M4, M4 Competition, M4 Competition M xDrive</td>
												<td>e1*2018/858*00003*02</td>
												<td>{getTextForFacts('section.typeApproval.values.valueExtension')}</td>
												<td>{moment('2021-06-29').format('L')}</td>
											</tr>
											<tr>
												<td>M3, M3 Competition, M4, M4 Competition, M4 Competition M xDrive</td>
												<td>e1*2018/858*00003*03</td>
												<td>{getTextForFacts('section.typeApproval.values.valueExtension')}</td>
												<td>{moment('2021-08-31').format('L')}</td>
											</tr>
											<tr>
												<td>
													M3, M3 Competition, M3 Competition M xDrive, M4, M4 Competition, M4 Competition M
													xDrive
												</td>
												<td>e1*2018/858*00003*04</td>
												<td>{getTextForFacts('section.typeApproval.values.valueExtension')}</td>
												<td>{moment('2022-05-16').format('L')}</td>
											</tr>
											<tr>
												<td>
													M3, M3 Competition, M3 Competition M xDrive, M4, M4 Competition, M4 Competition M
													xDrive
												</td>
												<td>e1*2018/858*00003*05</td>
												<td>{getTextForFacts('section.typeApproval.values.valueExtension')}</td>
												<td>{moment('2022-10-10').format('L')}</td>
											</tr>
											<tr>
												<td>
													M3, M3 Competition, M3 Competition M xDrive, M4, M4 Competition, M4 Competition M
													xDrive
												</td>
												<td>e1*2018/858*00003*06</td>
												<td>{getTextForFacts('section.typeApproval.values.valueExtension')}</td>
												<td>{moment('2023-02-08').format('L')}</td>
											</tr>
											<tr>
												<td>
													M3, M3 Competition, M3 Competition M xDrive, M4, M4 Competition, M4 Competition M
													xDrive
												</td>
												<td>e1*2018/858*00003*07</td>
												<td>{getTextForFacts('section.typeApproval.values.valueExtension')}</td>
												<td>{moment('2024-01-26').format('L')}</td>
											</tr>
										</tbody>
									</table>
								</td>
							</tr>
						</tbody>
					</table>

					<SourceReference
						source="KBA"
						dateAccessed="2024-05-29"
						url="https://www.kba.de/DE/Themen/Typgenehmigung/CoC_Daten_Fahrzeugtypdaten/CoC_Daten_Fahrzeugtypdaten_node.html"
					/>
				</div>
			</div>
			<div className="row">
				<div className="col mb-5">
					<SectionHeader title={getTextForFacts('section.registrations.header')} />
					<p>{getTextForFacts('section.registrations.subtitle')}</p>
					<table className="table table-striped">
						<thead className="table-light">
							<tr>
								<th>{getTextForFacts('section.registrations.table.headerModelCode')}</th>
								<th>{getTextForFacts('section.registrations.table.headerModelName')}</th>
								<th>{getTextForFacts('section.registrations.table.headerHSN')}</th>
								<th>{getTextForFacts('section.registrations.table.headerTSN')}</th>
								{distinctDates.map((date, index) => (
									<th key={'column_' + index}>{moment(date, 'YYYY-MM-DD').format('L')}</th>
								))}
							</tr>
						</thead>
						<tbody>
							{vehicleRegistrationData.map((registration, index) => (
								<tr key={index}>
									<td>{registration.modelCode}</td>
									<td>{registration.modelName}</td>
									<td>{registration.manufacturerKeyCode}</td>
									<td>{registration.typeKeyCode}</td>
									{distinctDates.map((date, itemIndex) => {
										const matchingRegistration = registration.registrations.find((r) => r.date === date);
										return (
											<td key={'column_' + itemIndex}>
												{matchingRegistration ? matchingRegistration.amount : '-'}
											</td>
										);
									})}
								</tr>
							))}
						</tbody>
						<tfoot>
							<tr>
								<td colSpan={4}>{getTextForFacts('section.registrations.total')}</td>
								{distinctDates.map((date, index) => (
									<td key={'footer_' + index}>{calculateSumForDate(date)}</td>
								))}
							</tr>
						</tfoot>
					</table>
					<SourceReference
						source="KBA"
						dateAccessed="2023-12-23"
						url="https://www.kba.de/DE/Statistik/Fahrzeuge/Bestand/ListeHerstellerTypSchluesselnummern/typschluesselnummern_node.html"
					></SourceReference>
				</div>
			</div>
			<div className="row">
				<div className="col mb-5">
					<SectionHeader title={getTextForFacts('section.basePrices.header')} />
					<p>{getTextForFacts('section.basePrices.disclaimer')}</p>
					<table className="table table-striped">
						<thead className="table-light">
							<tr>
								<th></th>
								<th></th>
								<th colSpan={2}>{getTextForFacts('section.basePrices.table.headerPreLCI')}</th>
								<th colSpan={2}>{getTextForFacts('section.basePrices.table.headerLCI')}</th>
								<th></th>
							</tr>
							<tr>
								<th>{getTextForFacts('section.basePrices.table.headerCountry')}</th>
								<th>{getTextForFacts('section.basePrices.table.headerCurrency')}</th>
								<th>{getTextForFacts('section.basePrices.table.headerBasePrice')}</th>
								<th>{getTextForFacts('section.basePrices.table.headerDate')}</th>
								<th>{getTextForFacts('section.basePrices.table.headerBasePrice')}</th>
								<th>{getTextForFacts('section.basePrices.table.headerDate')}</th>
								<th>{getTextForFacts('section.basePrices.table.percentageIncrease')}</th>
							</tr>
						</thead>
						<tbody>
							{sortedBasePrices.map((listPrice: BaseListPrice, index) => (
								<tr key={index}>
									<td>{listPrice.country.toUpperCase()}</td>
									<td>{listPrice.currency}</td>
									<td>
										{listPrice.basePrice.toLocaleString(listPrice.country, {
											style: 'currency',
											currency: listPrice.currency,
										})}
									</td>
									<td>{moment(listPrice.date).format('L')}</td>
									<td>
										{listPrice.basePriceLCI === 0
											? getTextForFacts('section.basePrices.table.notYetAvailable')
											: listPrice.basePriceLCI.toLocaleString(listPrice.country, {
													style: 'currency',
													currency: listPrice.currency,
											  })}
									</td>
									<td>{listPrice.dateLCI === '' ? '-' : moment(listPrice.dateLCI).format('L')}</td>
									<td>{calculatePercentageIncrease(listPrice.basePriceLCI, listPrice.basePrice)}</td>
								</tr>
							))}
						</tbody>
					</table>
					<SourceReference source="BMW country specific sites" dateAccessed="2023-12-31" />
					<SourceReference source="BMW country specific sites" dateAccessed="2024-05-29" />
				</div>
			</div>
			<div className="row">
				<div className="col mb-5">
					<p>{getTextForFacts('section.general.footnoteHSNTSN')}</p>
				</div>
			</div>
		</div>
	);
}

export default Facts;
