import React, { useRef, useState } from 'react';
import Header from '../../components/Header';
import { useTranslation } from 'react-i18next';
import CountrySelection from '../../components/CountrySelection';
import ColorSelection from '../../components/ColorSelection';
import { Utils } from '../../common/Utils';
import MonthYearSelection from '../../components/MonthYearSelection';
import SocialMediaSelection from '../../components/SocialMediaSelection';

interface RegistrySignUpStatus {
	status: string;
	isError: boolean;
	responseReceived: boolean;
}

function RegistrySignUp() {
	const { t } = useTranslation(['translation', 'registry']);
	const getTextForRegistry = (key: string): string => {
		return t(key, { ns: 'registry' });
	};
	const formRef = useRef<HTMLFormElement>(null);
	const [formStatus, setFormStatus] = useState<RegistrySignUpStatus>({
		status: '',
		isError: false,
		responseReceived: false,
	});
	const [hasAgreedToContact, setHasAgreedToContact] = useState(false);

	const handleCheckboxChangeContact = (event: React.ChangeEvent<HTMLInputElement>) => {
		setHasAgreedToContact(event.target.checked);
	};

	const [hasAgreedToDataProcessing, setHasAgreedToDataProcessing] = useState(false);

	const handleCheckboxChangeDataProcessing = (event: React.ChangeEvent<HTMLInputElement>) => {
		setHasAgreedToDataProcessing(event.target.checked);
	};

	const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault(); // Prevent the default form submission

		const form = event.currentTarget;
		const inputUserName = form.querySelector('#inputUserName') as HTMLInputElement;
		const inputMail = form.querySelector('#inputEmail') as HTMLInputElement;
		const inputVin = form.querySelector('#inputVin') as HTMLTextAreaElement;
		const termsAgree = form.querySelector('#termsAgree') as HTMLInputElement;

		if (inputUserName && inputMail && inputVin && termsAgree) {
			// Check validity and handle custom validation
			if (
				inputUserName.value.trim() === '' ||
				inputMail.value.trim() === '' ||
				!Utils.isEmailValid(inputMail.value.trim()) ||
				inputVin.value.trim() === '' ||
				!termsAgree.checked
			) {
				// Show validation errors
				form.classList.add('was-validated');
			} else {
				// If all validations pass, proceed with form submission
				form.classList.remove('was-validated');

				// Submit the form
				// Assuming your form's action attribute points to the PHP script
				//form.submit();
				await performSubmit(event);
			}
		}
	};

	const performSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		const formData = new FormData(event.currentTarget);

		try {
			const response = await fetch('/scripts/signup.php', {
				method: 'POST',
				body: formData,
			});

			const responseData = await response.json();
			setFormStatus({
				...formStatus,
				status: responseData.status,
				isError: responseData.status !== 'success',
				responseReceived: true,
			});
			resetFormFields();
		} catch (error) {
			setFormStatus({
				...formStatus,
				status: 'network_error',
				isError: true,
				responseReceived: true,
			});
		}
	};

	const resetFormFields = () => {
		if (formRef.current) {
			formRef.current.reset();
		}
	};

	return (
		<div id="registrySignUp" className="g81-page">
			<div className="row">
				<Header title={t('app.navigation.registrySignUp')} />
			</div>
			<div className="row">
				<div className="col">
					<form ref={formRef} className="mt-3 needs-validation" noValidate onSubmit={handleFormSubmit}>
						<div className="mb-3">
							<h5>{getTextForRegistry('personalInfoHeader')}</h5>
							<div className="row mb-3">
								<label htmlFor="inputUserName" className="col-sm-3 col-form-label">
									{getTextForRegistry('userName')}
								</label>
								<div className="col-sm-5">
									<input
										name="userName"
										type="text"
										className="form-control"
										id="inputUserName"
										placeholder={getTextForRegistry('userName')}
										required
									/>
									<div className="valid-feedback">{getTextForRegistry('validationGood')}</div>
								</div>
								<div className="col-sm-4">
									<div id="userNameHelp" className="form-text">
										{getTextForRegistry('userNameInfo')}
									</div>
								</div>
							</div>
							<div className="row mb-3">
								<label htmlFor="inputEmail" className="col-sm-3 col-form-label">
									{getTextForRegistry('email')}
								</label>
								<div className="col-sm-5">
									<input
										name="email"
										type="email"
										className="form-control"
										id="inputEmail"
										placeholder="name@example.com"
										required
									/>
									<div className="valid-feedback">{getTextForRegistry('validationGood')}</div>
								</div>
								<div className="col-sm-4">
									<div id="emailHelp" className="form-text">
										{getTextForRegistry('emailInfo')}
									</div>
								</div>
							</div>
							<div className="row mb-3">
								<label htmlFor="inputCountryOfResidence" className="col-sm-3 col-form-label">
									{getTextForRegistry('countryOfRegistration')}
								</label>
								<div className="col-sm-5">
									<CountrySelection formName="countryOfResidence" required={true} />
									<div className="valid-feedback">{getTextForRegistry('validationGood')}</div>
								</div>
								<div className="col-sm-4">
									<div id="residenceHelp" className="form-text">
										{getTextForRegistry('countryOfRegistrationInfo')}
									</div>
								</div>
							</div>
							<div className="row mb-3">
								<label htmlFor="inputSocial" className="col-sm-3 col-form-label">
									{getTextForRegistry('socialMedia')}
								</label>
								<div className="col-sm-5">
									<SocialMediaSelection
										formNamePlatform="socialMediaPlatform"
										formNameAccount="socialMediaAccount"
									/>
								</div>
								<div className="col-sm-4">
									<div id="socialmediaHelp" className="form-text">
										{getTextForRegistry('socialMediaInfo')}
									</div>
								</div>
							</div>
						</div>
						<div className="mb-3">
							<h5>{getTextForRegistry('vehicleInfoHeader')}</h5>
							<div className="row mb-3">
								<label htmlFor="inputVin" className="col-sm-3 col-form-label">
									{getTextForRegistry('vin')}
								</label>
								<div className="col-sm-5">
									<input
										name="vin"
										type="text"
										className="form-control"
										id="inputVin"
										placeholder={getTextForRegistry('vinPlaceholder')}
										required
									/>
									<div className="valid-feedback">{getTextForRegistry('validationGood')}</div>
								</div>
								<div className="col-sm-4">
									<div id="vinHelp" className="form-text">
										{getTextForRegistry('vinInfo')}
									</div>
								</div>
							</div>
							<div className="row mb-3">
								<label htmlFor="inputInitialRegistration" className="col-sm-3 col-form-label">
									{getTextForRegistry('initialRegistrationDate')}
								</label>
								<div className="col-sm-5">
									<MonthYearSelection
										formNameMonth="initialRegistrationMonth"
										formNameYear="initialRegistrationYear"
									/>
								</div>
								<div className="col-sm-4">
									<div id="initialRegistrationHelp" className="form-text">
										{getTextForRegistry('initialRegistrationDateInfo')}
									</div>
								</div>
							</div>
							<div className="row mb-3">
								<label htmlFor="inputCountryOfRegistration" className="col-sm-3 col-form-label">
									{getTextForRegistry('countryOfRegistration')}
								</label>
								<div className="col-sm-5">
									<CountrySelection formName="countryOfRegistration" required={true} />
									<div className="valid-feedback">{getTextForRegistry('validationGood')}</div>
								</div>
								<div className="col-sm-4">
									<div id="registrationHelp" className="form-text">
										{getTextForRegistry('countryOfRegistrationInfo')}
									</div>
								</div>
							</div>
							<div className="row mb-3">
								<label htmlFor="inputVehicleColor" className="col-sm-3 col-form-label">
									{getTextForRegistry('exteriorColor')}
								</label>
								<div className="col-sm-5">
									<ColorSelection formName="colorCode" />
								</div>
								<div className="col-sm-4">
									<div id="colorHelp" className="form-text">
										{getTextForRegistry('exteriorColorInfo')}
									</div>
								</div>
							</div>
							<div className="row mb-3">
								<label htmlFor="inputOwnership" className="col-sm-3 col-form-label">
									{getTextForRegistry('ownedSince')}
								</label>
								<div className="col-sm-5">
									<MonthYearSelection
										formNameMonth="ownedSinceMonth"
										formNameYear="ownedSinceYear"
										required={true}
									/>
								</div>
								<div className="col-sm-4">
									<div id="ownershipHelp" className="form-text">
										{getTextForRegistry('ownedSinceInfo')}
									</div>
								</div>
							</div>
						</div>
						<div className="mb-3">
							<div className="mb-3">
								<div className="form-check">
									<input
										name="hasAgreedInDataProcessing"
										className="form-check-input"
										type="checkbox"
										id="termsAgree"
										checked={hasAgreedToDataProcessing}
										onChange={handleCheckboxChangeDataProcessing}
										value={'true'}
										required
									/>
									<label className="form-check-label" htmlFor="termsAgree">
										{getTextForRegistry('termsPrivacyAgree')}
									</label>
									<div className="invalid-feedback">{getTextForRegistry('termsPrivacyAgreeInfo')}</div>
								</div>
							</div>
							<div className="mb-3">
								<div className="form-check">
									<input
										name="hasAgreedToContact"
										className="form-check-input"
										type="checkbox"
										id="subscribeAgree"
										checked={hasAgreedToContact}
										onChange={handleCheckboxChangeContact}
										value={'true'}
										required
									/>
									<label className="form-check-label" htmlFor="subscribeAgree">
										{getTextForRegistry('subscribeAgree')}
									</label>
									<div className="invalid-feedback">{getTextForRegistry('subscribeAgreeInfo')}</div>
								</div>
							</div>
						</div>
						<div className="mb-3">
							<button className="btn btn-primary" type="submit">
								{getTextForRegistry('submitButton')}
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}

export default RegistrySignUp;
