import React from 'react';
import { useTranslation } from 'react-i18next';
import colorsData from '../data/exteriorColors.json';
import { Color } from '../common/Types';

interface ColorSelectionProps {
	formName?: string;
}

const ColorSelection: React.FC<ColorSelectionProps> = ({ formName }) => {
	const { t } = useTranslation(['translation', 'colors']);
	const getTextForColors = (key: string): string => {
		return t(key, { ns: 'colors' });
	};

	const colorsArray: Color[] = Object.entries(colorsData).map(([key, value]) => ({
		...value,
		code: key, // Assigning the object key as 'code' property
	}));

	// Separate standard and non-standard colors
	const standardColors = colorsArray
		.filter((color) => color.isStandard)
		.sort((a, b) => a.name.localeCompare(b.name));
	const nonStandardColors = colorsArray.filter((color) => !color.isStandard);

	// Sort non-standard colors by range and alphabetically within each range
	const sortedNonStandardColors = Object.values(
		nonStandardColors.reduce((acc: { [key: string]: Color[] }, color: Color) => {
			if (!acc[color.colorRange]) {
				acc[color.colorRange] = [];
			}
			acc[color.colorRange].push(color);
			return acc;
		}, {})
	).map((colors) => colors.sort((a, b) => a.name.localeCompare(b.name)));

	// Function to render options for each group
	const renderGroupedOptions = (colors: Color[], label: string) => (
		<>
			<option disabled key={label}>
				---{getTextForColors('colorRanges.' + label)}---
			</option>
			{colors.map((color) => (
				<option value={color.code} key={color.code}>
					{color.name} ({color.code})
				</option>
			))}
		</>
	);

	return (
		<select
			name={formName}
			id="colorSelection"
			className="form-select"
			aria-label={getTextForColors('initialSelection')}
		>
			<option selected disabled value="-1" key="initial">
				{getTextForColors('initialSelection')}
			</option>
			<option disabled key={getTextForColors('headers.standardColors')}>
				------{getTextForColors('headers.standardColors')}------
			</option>
			{standardColors.map((color) => (
				<option value={color.code} key={color.code}>
					{color.name} ({color.code})
				</option>
			))}
			<option disabled key={getTextForColors('headers.individualColors')}>
				------{getTextForColors('headers.individualColors')}------
			</option>
			{sortedNonStandardColors.map((group: Color[], index: number) =>
				group.length > 0
					? renderGroupedOptions(group, group[0].colorRange || getTextForColors('colorRanges.other'))
					: null
			)}
		</select>
	);
};

export default ColorSelection;
