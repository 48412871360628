import React from 'react';

interface HeaderProps {
	title: string;
}

const Header: React.FC<HeaderProps> = ({ title }) => {
	return (
		<div id="header" className="g81-header">
			<h1>
				<b>{title}</b>
			</h1>
		</div>
	);
};

export default Header;
