import React from 'react';
import Header from '../../components/Header';
import { useTranslation } from 'react-i18next';
import mediaPress from '../../data/mediaPress.json';
import SectionHeader from '../../components/SectionHeader';
const moment = require('moment');

function PrintMediaBlogs() {
	const { t } = useTranslation(['translation', 'printMediaBlogs']);
	const getTextForPrintMediaBlogs = (key: string): string => {
		return t(key, { ns: 'printMediaBlogs' });
	};
	mediaPress.sort((a, b) => moment(b.publishedOn, 'YYYY-MM-DD').diff(moment(a.publishedOn, 'YYYY-MM-DD')));

	return (
		<div id="m3Touring-media" className="g81-page">
			<div className="row">
				<Header title={getTextForPrintMediaBlogs('header')} />
			</div>
			<div className="row">
				<div className="col mb-5">
					<SectionHeader title={getTextForPrintMediaBlogs('section.print.header')} icon="bi-newspaper" />
					<table className="table table-striped">
						<thead className="table-light">
							<tr>
								<th>{getTextForPrintMediaBlogs('general.publishedOn')}</th>
								<th>{getTextForPrintMediaBlogs('general.title')}</th>
								<th>{getTextForPrintMediaBlogs('general.source')}</th>
								<th>{getTextForPrintMediaBlogs('general.categories')}</th>
								<th>{getTextForPrintMediaBlogs('general.language')}</th>
							</tr>
						</thead>
						<tbody>
							{mediaPress.map((mediaItem, index) => (
								<tr key={index}>
									<td>{moment(mediaItem.publishedOn).format('L')}</td>
									<td>
										<a href={mediaItem.sourceUrl} target="_blank" rel="noreferrer">
											{mediaItem.title}
										</a>
									</td>
									<td>
										<a href={mediaItem.publisherUrl} target="_blank" rel="noreferrer">
											{mediaItem.publisherName}
										</a>
									</td>
									<td>{getTextForPrintMediaBlogs('general.' + mediaItem.typeKey)}</td>
									<td>{mediaItem.country}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
}

export default PrintMediaBlogs;
