import React from 'react';
import { useTranslation } from 'react-i18next';
const moment = require('moment');

interface SourceReferenceProps {
	url?: string;
	source: string;
	dateAccessed: string;
}

const SourceReference: React.FC<SourceReferenceProps> = ({ url, source, dateAccessed }) => {
	const { t } = useTranslation();
	const dateFormatted = moment(dateAccessed).format('L');
	return (
		<div className="g81-source d-flex justify-content-end">
			<figure>
				<b>{t('general.source')}: </b>
				{url ? (
					<a href={url} target="_blank" rel="noreferrer">
						{source}
					</a>
				) : (
					<span>{source}</span>
				)}
				<br />({t('general.accessedOn')}: {dateFormatted})
				<br />
				{t('general.sourceDisclaimer')}
			</figure>
		</div>
	);
};

export default SourceReference;
