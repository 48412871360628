import React from 'react';
import Header from '../../components/Header';
import { useTranslation } from 'react-i18next';

function Privacy() {
	const { t } = useTranslation();
	return (
		<div id="privacy" className="g81-page">
			<div className="row">
				<Header title={t('page.privacy.header')} />
			</div>
			<div className="row">
				<div className="col"></div>
			</div>
		</div>
	);
}

export default Privacy;
