import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

function ActivateMember() {
	const { t } = useTranslation();
	const { search } = useLocation();
	const { action, code } = Object.fromEntries(new URLSearchParams(search));
	const [showSuccess, setShowSuccess] = useState(false);
	const [showError, setShowError] = useState(false);

	useEffect(() => {
		// Validate action and code
		console.log('Effect triggered!');
		if (action === 'activate' && code && code.length === 32) {
			const currentDomain = window.location.origin;
			// Perform fetch request
			fetch(`${currentDomain}/scripts/member.php?action=activate&code=${code}`)
				.then((response) => response.json())
				.then((data) => {
					// Check the status field in the JSON response
					if (data.status === 'success') {
						setShowSuccess(true);
						setShowError(false);
						// Additional actions if activation is successful
					} else {
						setShowSuccess(false);
						setShowError(true);
						console.log('Error during activation:', data.statusCode);
						// Additional actions if activation fails
					}
				})
				.catch((error) => {
					console.error('Error during activation:', error);
					setShowSuccess(false);
					setShowError(true);
					// Additional actions if there is an error during activation
				});
		} else {
			console.error('Invalid parameters');
			setShowSuccess(false);
			setShowError(true);
			// Additional actions if parameters are invalid
		}
	}, [action, code]);

	return (
		<div id="faq" className="g81-page">
			<div className="row">
				<Header title={t('page.activateMember.header')} />
			</div>
			<div className="row">
				<div className="col">
					{showSuccess && (
						<div className="alert alert-success" role="alert">
							Your registration has been completed.
						</div>
					)}

					{showError && (
						<div className="alert alert-danger" role="alert">
							An error occurred, please ask for support via the Contact page.
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default ActivateMember;
