import React, { useEffect, useState } from 'react';
import destinationsJson from '../../data/destinations.json';
import Header from '../../components/Header';
import { useTranslation } from 'react-i18next';
import { Destination, DestinationType } from '../../common/Types';
import GoogleMapContainer from '../../components/GoogleMapContainer';

function Destinations() {
	const { t } = useTranslation(['translation', 'destinations']);
	const getTextForDestinations = (key: string): string => {
		return t(key, { ns: 'destinations' });
	};

	const [filters, setFilters] = useState<Record<DestinationType, boolean>>({
		museum: true,
		historical: true,
		manufacturer: true,
		scenicRoad: true,
		mountainPass: true,
		raceTrack: true,
	});

	const handleCheckboxChange = (type: DestinationType) => (event: React.ChangeEvent<HTMLInputElement>) => {
		setFilters((prevFilters) => ({
			...prevFilters,
			[type]: event.target.checked,
		}));
	};
	const [locations, setLocations] = useState<Destination[]>([]);
	const [filteredLocations, setFilteredLocations] = useState<Destination[]>([]);

	const renderCheckbox = (type: DestinationType, label: string) => {
		const idAlias = type.toLowerCase();
		return (
			<>
				<input
					type="checkbox"
					className="btn-check"
					id={`toggle-${idAlias}`}
					autoComplete="off"
					checked={filters[type]}
					onChange={handleCheckboxChange(type)}
				/>
				<label className={`btn btn-outline-m-dest-${idAlias}`} htmlFor={`toggle-${idAlias}`}>
					{label}
				</label>
			</>
		);
	};

	useEffect(() => {
		const initialFilteredLocations = locations.filter((location) => filters[location.primaryType]);
		setFilteredLocations(initialFilteredLocations);
	}, [filters, locations]);

	useEffect(() => {
		setLocations(destinationsJson as Destination[]);
	}, []);

	return (
		<div id="drive-destinations" className="g81-page">
			<div className="row">
				<Header title={getTextForDestinations('header')} />
			</div>
			<div className="row">
				<div className="col">
					{renderCheckbox('museum', 'Museum')}
					{renderCheckbox('historical', 'Historical')}
					{renderCheckbox('manufacturer', 'Manufacturer')}
					{renderCheckbox('scenicRoad', 'Scenic Road')}
					{renderCheckbox('mountainPass', 'Mountain Pass')}
					{renderCheckbox('raceTrack', 'Race Track')}
				</div>
			</div>
			<div className="row">
				<div className="col">
					<GoogleMapContainer locations={filteredLocations} />
				</div>
			</div>
		</div>
	);
}

export default Destinations;
