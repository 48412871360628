import { Color, ColorList, Country, SocialMediaPlatformType, Window } from './Types';
import countryList from '../data/countries.json';
import exteriorColors from '../data/exteriorColors.json';

export class Utils {
	public static getWindow(): Window {
		const _unknownWin = window as unknown;
		return _unknownWin as Window;
	}
	public static getDefaultLang(): string {
		return 'en';
	}
	public static getSupportedLanguages(): string[] {
		return ['en', 'de'];
	}
	public static isVINValid(vin: string): boolean {
		if (vin.length === 17) {
			const pattern = /^WBS11GB[A-Z0-9]{3}[AFGK][A-Z0-9]{6}$/;
			return pattern.test(vin.toUpperCase());
		}
		return false;
	}

	public static isEmailValid(email: string): boolean {
		const pattern =
			/^(?!.*[`'\"\x00])(?!.{255,})(?!.*@.{64,})(?![^@]+@[^a-zA-Z0-9.-]+$)[a-zA-Z0-9._%+-]{1,63}@[a-zA-Z0-9.-]{1,}\.[a-zA-Z]{2,}$/;
		return pattern.test(email.toLowerCase());
	}

	public static getSocialMediaPlatform(platformString: string): SocialMediaPlatformType | undefined {
		const normalizedPlatformString = platformString.toLowerCase();

		if (normalizedPlatformString === 'youtube') {
			return 'youtube';
		} else if (normalizedPlatformString === 'facebook') {
			return 'facebook';
		} else if (normalizedPlatformString === 'instagram') {
			return 'instagram';
		} else if (normalizedPlatformString === 'twitter_x') {
			return 'twitter_x';
		} else {
			return undefined; // Not a valid platform
		}
	}

	public static isSocialMediaAccountNameValid(
		platform: SocialMediaPlatformType,
		accountName: string
	): boolean {
		switch (platform) {
			case 'youtube':
				// YouTube username validation logic
				//const isValid= /^https?:\/\/(www\.)?youtube\.com\/(channel\/UC[\w-]{21}[AQgw]|(c\/|user\/)?[\w-]+)$.test(accountName);
				return true;

			case 'facebook':
				// Facebook username validation logic
				return true;

			case 'instagram':
				// Instagram username validation logic
				return true;

			case 'twitter_x':
				// X (formerly known as Twitter) username validation logic
				return true;

			default:
				return false; // Invalid platform
		}
	}

	public static getSocialMediaUrl(
		platform: SocialMediaPlatformType,
		accountName: string
	): string | undefined {
		if (platform && platform.trim().length > 0 && accountName && accountName.trim().length > 0) {
			if (Utils.isSocialMediaAccountNameValid(platform, accountName)) {
				switch (platform) {
					case 'youtube':
						return `https://youtube.com/${accountName}`;
					case 'instagram':
						return `https://instagram.com/${accountName}`;
					case 'facebook':
						return `https://facebook.com/${accountName}`;
					case 'twitter_x':
						return `https://x.com/${accountName}`;
				}
			}
		}
		return undefined;
	}

	public static getCountryByCode(code: string): Country | undefined {
		return countryList.find((country) => country.code === code);
	}

	public static getColorByCode(code: string): Color | undefined {
		const colorList = exteriorColors as ColorList;
		return colorList[code] as Color;
	}

	public static rgbStringToArray(rgbString: string): number[] | null {
		const regex = /rgb\((\d+),\s*(\d+),\s*(\d+)\)/;
		const match = rgbString.match(regex);

		if (match) {
			const [, r, g, b] = match.map(Number);
			return [r, g, b];
		}

		return null;
	}

	// Convert RGB to HSL
	public static rgbToHsl(r: number, g: number, b: number): number[] {
		r /= 255;
		g /= 255;
		b /= 255;

		const max = Math.max(r, g, b);
		const min = Math.min(r, g, b);
		let h = 0,
			s = 0,
			l = (max + min) / 2;

		if (max !== min) {
			const d = max - min;
			s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
			switch (max) {
				case r:
					h = (g - b) / d + (g < b ? 6 : 0);
					break;
				case g:
					h = (b - r) / d + 2;
					break;
				case b:
					h = (r - g) / d + 4;
					break;
			}
			h /= 6;
		}

		return [h, s, l];
	}

	public static generateGradient(rgbColor: number[], type: string = 'metallic'): string {
		const [h, s, l] = Utils.rgbToHsl(rgbColor[0], rgbColor[1], rgbColor[2]);

		let stops: string[];

		if (type === 'uni') {
			stops = [
				`hsl(${h * 360},${s * 100}%,${l * 80}%) 0%`,
				`hsl(${h * 360},${s * 100}%,${l * 100}%) 25%`,
				`hsl(${h * 360},${s * 100}%,${l * 100}%) 40%`,
				`hsl(${h * 360},${s * 100}%,${l * 120}%) 50%`,
				`hsl(${h * 360},${s * 100}%,${l * 100}%) 52%`,
				`hsl(${h * 360},${s * 100}%,${l * 80}%) 68%`,
				`hsl(${h * 360},${s * 100}%,${l * 60}%) 69%`,
				`hsl(${h * 360},${s * 100}%,${l * 100}%) 90%`,
				`hsl(${h * 360},${s * 100}%,${l * 80}%) 95%`,
			];
		} else if (type === 'frozen') {
			stops = [
				`hsl(${h * 360},${s * 100}%,${l * 80}%) 0%`,
				`hsl(${h * 360},${s * 100}%,${l * 100}%) 25%`,
				`hsl(${h * 360},${s * 100}%,${l * 100}%) 40%`,
				`hsl(${h * 360},${s * 100}%,${l * 110}%) 50%`,
				`hsl(${h * 360},${s * 100}%,${l * 110}%) 52%`,
				`hsl(${h * 360},${s * 100}%,${l * 80}%) 68%`,
				`hsl(${h * 360},${s * 100}%,${l * 75}%) 70%`,
				`hsl(${h * 360},${s * 100}%,${l * 100}%) 90%`,
				`hsl(${h * 360},${s * 100}%,${l * 80}%) 95%`,
			];
		} else {
			stops = [
				`hsl(${h * 360},${s * 100}%,${l * 80}%) 0%`,
				`hsl(${h * 360},${s * 100}%,${l * 100}%) 25%`,
				`hsl(${h * 360},${s * 100}%,${l * 100}%) 40%`,
				`hsl(${h * 360},${s * 100}%,${l * 140}%) 50%`,
				`hsl(${h * 360},${s * 100}%,${l * 100}%) 52%`,
				`hsl(${h * 360},${s * 100}%,${l * 80}%) 68%`,
				`hsl(${h * 360},${s * 100}%,${l * 60}%) 69%`,
				`hsl(${h * 360},${s * 100}%,${l * 100}%) 90%`,
				`hsl(${h * 360},${s * 100}%,${l * 80}%) 95%`,
			];
		}

		const gradient = `linear-gradient(170deg, ${stops.join(', ')})`;

		return gradient;
	}
}
