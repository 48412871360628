import React from 'react';
import Header from '../../components/Header';
import { useTranslation } from 'react-i18next';
import SourceReference from '../../components/SourceReference';
import SectionHeader from '../../components/SectionHeader';

function TechnicalData() {
	const { t } = useTranslation(['translation', 'techSpecs', 'tyres']);
	const getTextForTechSpecs = (key: string): string => {
		return t(key, { ns: 'techSpecs' });
	};
	const getTextForTyres = (key: string): string => {
		return t(key, { ns: 'tyres' });
	};
	return (
		<div id="m3Touring-technical-data" className="g81-page">
			<div className="row">
				<Header title={getTextForTechSpecs('header')} />
			</div>
			<div className="row">
				<div className="col mb-5">
					<SectionHeader title={getTextForTechSpecs('header')} icon="bi-clipboard2-data" />
					<table className="table table-sm table-striped">
						<thead className="table-light">
							<tr>
								<th></th>
								<th></th>
								<th style={{ width: '50%' }}>
									<b>{getTextForTechSpecs('model')}</b>
								</th>
								<th style={{ width: '25%' }}>
									<b>{getTextForTechSpecs('modelLci')}</b>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td></td>
								<td></td>
								<td></td>
								<td>{getTextForTechSpecs('modelLciAnnotation')}</td>
							</tr>
							<tr>
								<td>
									<b>{getTextForTechSpecs('body.header')}</b>
								</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('body.labels.numberOfDoorsSeats')}</td>
								<td></td>
								<td>5 / 5</td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('body.labels.lengthWidthHeightUnloaded')}</td>
								<td>{getTextForTechSpecs('units.millimeter')}</td>
								<td>4801 / 1903 / 1446</td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('body.labels.wheelbase')}</td>
								<td>{getTextForTechSpecs('units.millimeter')}</td>
								<td>2857</td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('body.labels.trackFrontRear')}</td>
								<td>{getTextForTechSpecs('units.millimeter')}</td>
								<td>1617 / 1605</td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('body.labels.groundClearance')}</td>
								<td>{getTextForTechSpecs('units.millimeter')}</td>
								<td>123</td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('body.labels.turningCircle')}</td>
								<td>{getTextForTechSpecs('units.meter')}</td>
								<td>12,6</td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('body.labels.fuelTankCapacity')}</td>
								<td>
									{getTextForTechSpecs('units.approx')} {t('units.liter')}
								</td>
								<td>59</td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('body.labels.engineOil')}</td>
								<td>{getTextForTechSpecs('units.millimeter')}</td>
								<td>7,0</td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('body.labels.weightUnloadedDINEU')}</td>
								<td>{getTextForTechSpecs('units.kilogram')}</td>
								<td>1865 / 1940</td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('body.labels.maxLoadToDIN')}</td>
								<td>{getTextForTechSpecs('units.kilogram')}</td>
								<td>505</td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('body.labels.maxPermissibleWeight')}</td>
								<td>{getTextForTechSpecs('units.kilogram')}</td>
								<td>2370</td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('body.labels.maxAxleLoadFrontRear')}</td>
								<td>{getTextForTechSpecs('units.kilogram')}</td>
								<td>1125 / 1275</td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('body.labels.maxTrailerLoadBraked12Unbraked')}</td>
								<td>{getTextForTechSpecs('units.kilogram')}</td>
								<td>– / –</td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('body.labels.maxRoofloadTowbarDownload')}</td>
								<td>{getTextForTechSpecs('units.kilogram')}</td>
								<td>75 / –</td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('body.labels.luggageCompCapacity')}</td>
								<td>{getTextForTechSpecs('units.liter')}</td>
								<td>500 – 1510</td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('body.labels.airResistance')}</td>
								<td>{getTextForTechSpecs('units.airResistance')}</td>
								<td>0,36 x 2,34</td>
								<td>0,36 x 2,35</td>
							</tr>
							<tr>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>
									<b>{getTextForTechSpecs('powerUnit.header')}</b>
								</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('powerUnit.labels.configNoOfCylsValves')}</td>
								<td></td>
								<td>R / 6 / 4</td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('powerUnit.labels.engineTechnology')}</td>
								<td></td>
								<td>{getTextForTechSpecs('powerUnit.content.engineTechnology')}</td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('powerUnit.labels.effectiveCapacity')}</td>
								<td>{getTextForTechSpecs('units.cubicMeter')}</td>
								<td>2993</td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('powerUnit.labels.strokeBore')}</td>
								<td>{getTextForTechSpecs('units.millimeter')}</td>
								<td>90,0 / 84,0</td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('powerUnit.labels.compressionRatio')}</td>
								<td>{getTextForTechSpecs('units.ratioToOne')}</td>
								<td>9,3</td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('powerUnit.labels.fuel')}</td>
								<td></td>
								<td>{getTextForTechSpecs('powerUnit.content.fuel')}</td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('powerUnit.labels.maxOutput')}</td>
								<td>{getTextForTechSpecs('units.power')}</td>
								<td>375 / 510</td>
								<td>390 / 530</td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('powerUnit.labels.at')} </td>
								<td>{getTextForTechSpecs('units.rpm')}</td>
								<td>6250</td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('powerUnit.labels.maxTorque')}</td>
								<td>{getTextForTechSpecs('units.torque')}</td>
								<td>650</td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('powerUnit.labels.at')}</td>
								<td>{getTextForTechSpecs('units.rpm')}</td>
								<td>2750 – 5500</td>
								<td>2750 – 5730</td>
							</tr>
							<tr>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>
									<b>{getTextForTechSpecs('electric.header')}</b>
								</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('electric.labels.batteryInstallation')}</td>
								<td>{getTextForTechSpecs('units.batteryCapacity')}/–</td>
								<td>{getTextForTechSpecs('electric.content.batteryInstallation')}</td>
								<td></td>
							</tr>
							<tr>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>
									<b>{getTextForTechSpecs('dynamicsAndSafety.header')}</b>
								</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('dynamicsAndSafety.labels.suspensionFront')}</td>
								<td></td>
								<td>{getTextForTechSpecs('dynamicsAndSafety.content.suspensionFront')}</td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('dynamicsAndSafety.labels.suspensionRear')}</td>
								<td></td>
								<td>{getTextForTechSpecs('dynamicsAndSafety.content.suspensionRear')}</td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('dynamicsAndSafety.labels.brakesFront')}</td>
								<td></td>
								<td>{getTextForTechSpecs('dynamicsAndSafety.content.brakesFront')}</td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('dynamicsAndSafety.labels.brakesRear')}</td>
								<td></td>
								<td>{getTextForTechSpecs('dynamicsAndSafety.content.brakesRear')}</td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('dynamicsAndSafety.labels.drivingStabilitySystems')}</td>
								<td></td>
								<td>{getTextForTechSpecs('dynamicsAndSafety.content.drivingStabilitySystems')}</td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('dynamicsAndSafety.labels.safetyEquipment')}</td>
								<td></td>
								<td>{getTextForTechSpecs('dynamicsAndSafety.content.safetyEquipment')}</td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('dynamicsAndSafety.labels.steering')}</td>
								<td></td>
								<td>{getTextForTechSpecs('dynamicsAndSafety.content.steering')}</td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('dynamicsAndSafety.labels.steeringRatioOverall')}</td>
								<td>{getTextForTechSpecs('units.ratioToOne')}</td>
								<td>14,6</td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('dynamicsAndSafety.labels.tyresFrontRear')}</td>
								<td></td>
								<td>
									275/35 ZR19 100Y XL<br></br>
									285/30 ZR20 99Y XL
								</td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('dynamicsAndSafety.labels.rimsFrontRear')}</td>
								<td></td>
								<td>{getTextForTechSpecs('dynamicsAndSafety.content.rimsFrontRear')}</td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('dynamicsAndSafety.labels.hubBore')}</td>
								<td>mm</td>
								<td>66.6</td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('dynamicsAndSafety.labels.boltPattern')}</td>
								<td>mm (in)</td>
								<td>5x112 (5x4.5)</td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('dynamicsAndSafety.labels.tighteningTorque')}</td>
								<td>Nm</td>
								<td>140</td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('dynamicsAndSafety.labels.threadSize')}</td>
								<td></td>
								<td>M14 x 1.25</td>
								<td></td>
							</tr>
							<tr>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>
									<b>{getTextForTechSpecs('transmission.header')}</b>
								</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('transmission.labels.typeOfTransmission')}</td>
								<td></td>
								<td>{getTextForTechSpecs('transmission.content.typeOfTransmission')}</td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('transmission.labels.gearRatios')} I</td>
								<td>{getTextForTechSpecs('units.ratioToOne')}</td>
								<td>5,000</td>
								<td></td>
							</tr>
							<tr>
								<td>II</td>
								<td>{getTextForTechSpecs('units.ratioToOne')}</td>
								<td>3,200</td>
								<td></td>
							</tr>
							<tr>
								<td>III</td>
								<td>{getTextForTechSpecs('units.ratioToOne')}</td>
								<td>2,143</td>
								<td></td>
							</tr>
							<tr>
								<td>IV</td>
								<td>{getTextForTechSpecs('units.ratioToOne')}</td>
								<td>1,720</td>
								<td></td>
							</tr>
							<tr>
								<td>V</td>
								<td>{getTextForTechSpecs('units.ratioToOne')}</td>
								<td>1,313</td>
								<td></td>
							</tr>
							<tr>
								<td>VI</td>
								<td>{getTextForTechSpecs('units.ratioToOne')}</td>
								<td>1,000</td>
								<td></td>
							</tr>
							<tr>
								<td>VII</td>
								<td>{getTextForTechSpecs('units.ratioToOne')}</td>
								<td>0,823</td>
								<td></td>
							</tr>
							<tr>
								<td>VIII</td>
								<td>{getTextForTechSpecs('units.ratioToOne')}</td>
								<td>0,640</td>
								<td></td>
							</tr>
							<tr>
								<td>R</td>
								<td>{getTextForTechSpecs('units.ratioToOne')}</td>
								<td>3,478</td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('transmission.labels.finalDrive')}</td>
								<td>{getTextForTechSpecs('units.ratioToOne')}</td>
								<td>3,154</td>
								<td></td>
							</tr>
							<tr>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>
									<b>{getTextForTechSpecs('performance.header')}</b>
								</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('performance.labels.powerToWeightRatioDIN')}</td>
								<td>{getTextForTechSpecs('units.powerToWeight')}</td>
								<td>4,97</td>
								<td>4,8</td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('performance.labels.outputPerLitre')}</td>
								<td>{getTextForTechSpecs('units.displacementEfficiency')}</td>
								<td>125,3</td>
								<td>130,3</td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('performance.labels.acceleration')} 0–100 km/h</td>
								<td>{getTextForTechSpecs('units.seconds')}</td>
								<td>3,6</td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('performance.labels.acceleration')} 0–200 km/h</td>
								<td>{getTextForTechSpecs('units.seconds')}</td>
								<td>12,9</td>
								<td>12,4</td>
							</tr>
							<tr>
								<td>
									{getTextForTechSpecs('performance.labels.acceleration')} 80–120 km/h
									<br></br>
									{getTextForTechSpecs('performance.labels.in4th5thGear')}
								</td>
								<td>{getTextForTechSpecs('units.seconds')}</td>
								<td>2,7 / 3,5</td>
								<td>3,5 {getTextForTechSpecs('performance.labels.in5thGearOnly')}</td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('performance.labels.topSpeed')}</td>
								<td>{getTextForTechSpecs('units.kph')}</td>
								<td>250 / 280 (2)</td>
								<td></td>
							</tr>
							<tr>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>
									<b>{getTextForTechSpecs('efficientDynamics.header')}</b>
								</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>
									{getTextForTechSpecs('efficientDynamics.labels.bmwEfficientDynamicsStandardFeatures')}
								</td>
								<td></td>
								<td>
									{getTextForTechSpecs('efficientDynamics.content.bmwEfficientDynamicsStandardFeatures')}
								</td>
								<td></td>
							</tr>
							<tr>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>
									<b>{getTextForTechSpecs('emissions.header')}</b>
								</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('emissions.labels.combinedWLTP')}</td>
								<td>{getTextForTechSpecs('units.consumption')}</td>
								<td>10,4 – 10,1</td>
								<td>10,4 – 10,3</td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('emissions.labels.combinedNEDC')}</td>
								<td>{getTextForTechSpecs('units.consumption')}</td>
								<td>-</td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('emissions.labels.co2WLTP')}</td>
								<td>{getTextForTechSpecs('units.co2Emissions')}</td>
								<td>235 – 229</td>
								<td>235 – 233</td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('emissions.labels.co2NEDC')}</td>
								<td>{getTextForTechSpecs('units.co2Emissions')}</td>
								<td>-</td>
								<td></td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('emissions.labels.emissionRating')}</td>
								<td></td>
								<td>Euro 6d</td>
								<td>Euro 6e</td>
							</tr>
							<tr>
								<td>{getTextForTechSpecs('emissions.labels.emissionClass')}</td>
								<td></td>
								<td>-</td>
								<td>G</td>
							</tr>
							<tr>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
						</tbody>
					</table>
					<div className="footnote">
						<p>{getTextForTechSpecs('emissions.subinfo')}</p>
						<p>
							{getTextForTechSpecs('emissions.footnote1')}
							<br />
							{getTextForTechSpecs('emissions.footnote2')}
							<br />
						</p>
						<p>
							{getTextForTechSpecs('emissions.disclaimer1')}
							<br />
							{getTextForTechSpecs('emissions.disclaimer2')}
							<br />
							{getTextForTechSpecs('emissions.disclaimer3')}
							<br />
						</p>
					</div>
					<SourceReference
						source="BMW PressClub Deutschland"
						dateAccessed="2023-12-23"
						url="https://www.press.bmwgroup.com/deutschland/article/detail/T0399336DE/der-erste-bmw-m3-touring"
					/>
					<SourceReference
						source="BMW PressClub Deutschland"
						dateAccessed="2024-05-29"
						url="https://www.press.bmwgroup.com/deutschland/article/detail/T0442275DE/die-neue-bmw-m3-limousine-der-neue-bmw-m3-touring"
					/>
				</div>
			</div>
			<div className="row">
				<div className="col mb-5">
					<SectionHeader title={getTextForTyres('section.rims.header')} />
					<p>{getTextForTyres('section.rims.infotext')}</p>

					<table className="table table-striped">
						<thead className="table-light">
							<tr>
								<th>{getTextForTyres('section.rims.combination')}</th>
								<th>{getTextForTyres('section.rims.table.fitment')}</th>
								<th>{getTextForTyres('section.rims.table.diameter')}</th>
								<th>{getTextForTyres('section.rims.table.width')}</th>
								<th>{getTextForTyres('section.rims.table.offset')}</th>
								<th>{getTextForTyres('section.rims.table.contour')}</th>
								<th>{getTextForTyres('section.rims.table.tyre')}</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Setup 1</td>
								<td colSpan={6}></td>
							</tr>
							<tr>
								<td></td>
								<td>{getTextForTyres('section.rims.front')}</td>
								<td>19"</td>
								<td>9"</td>
								<td>20</td>
								<td>J</td>
								<td>275/35 R19 100V</td>
							</tr>
							<tr>
								<td></td>
								<td>{getTextForTyres('section.rims.rear')}</td>
								<td>19"</td>
								<td>9,5"</td>
								<td>20</td>
								<td>J</td>
								<td>275/35 R19 100V</td>
							</tr>
							<tr>
								<td>Setup 2</td>
								<td colSpan={6}></td>
							</tr>
							<tr>
								<td></td>
								<td>{getTextForTyres('section.rims.front')}</td>
								<td>19"</td>
								<td>9,5"</td>
								<td>20</td>
								<td>J</td>
								<td>275/35 R19 100V</td>
							</tr>
							<tr>
								<td></td>
								<td>{getTextForTyres('section.rims.rear')}</td>
								<td>20"</td>
								<td>10,5"</td>
								<td>20</td>
								<td>J</td>
								<td>285/30 R20 99V</td>
							</tr>
							<tr>
								<td>Setup 3</td>
								<td colSpan={6}></td>
							</tr>
							<tr>
								<td></td>
								<td>{getTextForTyres('section.rims.front')}</td>
								<td>19"</td>
								<td>9,5"</td>
								<td>20</td>
								<td>J</td>
								<td>275/35 ZR19 100Y</td>
							</tr>
							<tr>
								<td></td>
								<td>{getTextForTyres('section.rims.rear')}</td>
								<td>20"</td>
								<td>10,5"</td>
								<td>20</td>
								<td>J</td>
								<td>285/30 ZR20 99Y</td>
							</tr>
							<tr>
								<td>Setup 4</td>
								<td colSpan={6}></td>
							</tr>
							<tr>
								<td></td>
								<td>{getTextForTyres('section.rims.front')}</td>
								<td>20"</td>
								<td>9,5"</td>
								<td>20</td>
								<td>J</td>
								<td>285/30 ZR20 99Y</td>
							</tr>
							<tr>
								<td></td>
								<td>{getTextForTyres('section.rims.rear')}</td>
								<td>21"</td>
								<td>10,5"</td>
								<td>20</td>
								<td>J</td>
								<td>295/25 ZR21 96Y</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div className="row">
				<div className="col mb-5">
					<SectionHeader title={getTextForTyres('header')} />
					<table className="table table-striped">
						<thead className="table-light">
							<tr>
								<th>{getTextForTyres('table.fitment')}</th>
								<th>{getTextForTyres('table.wheelSize')}</th>
								<th>{getTextForTyres('table.tyreDimension')}</th>
								<th>{getTextForTyres('table.stdRsc')}</th>
								<th>{getTextForTyres('table.manufacturer')}</th>
								<th>{getTextForTyres('table.link')}</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>{getTextForTyres('table.front')}</td>
								<td>19"</td>
								<td>275/35R 19 100V XL M+S</td>
								<td>STD</td>
								<td>Michelin</td>
								<td>
									<a href="https://eprel.ec.europa.eu/qr/409293" target="_blank" rel="noreferrer">
										https://eprel.ec.europa.eu/qr/409293
									</a>
								</td>
							</tr>
							<tr>
								<td>{getTextForTyres('table.front')}</td>
								<td>19"</td>
								<td>275/35R 19 100V XL M+S</td>
								<td>STD</td>
								<td>Pirelli</td>
								<td>
									<a href="https://eprel.ec.europa.eu/qr/597119" target="_blank" rel="noreferrer">
										https://eprel.ec.europa.eu/qr/597119
									</a>
								</td>
							</tr>
							<tr>
								<td>{getTextForTyres('table.rear')}</td>
								<td>19"</td>
								<td>275/35R 19 100V XL M+S</td>
								<td>STD</td>
								<td>Michelin</td>
								<td>
									<a href="https://eprel.ec.europa.eu/qr/409293" target="_blank" rel="noreferrer">
										https://eprel.ec.europa.eu/qr/409293
									</a>
								</td>
							</tr>
							<tr>
								<td>{getTextForTyres('table.rear')}</td>
								<td>19"</td>
								<td>275/35R 19 100V XL M+S</td>
								<td>STD</td>
								<td>Pirelli</td>
								<td>
									<a href="https://eprel.ec.europa.eu/qr/597119" target="_blank" rel="noreferrer">
										https://eprel.ec.europa.eu/qr/597119
									</a>
								</td>
							</tr>
							<tr>
								<td>{getTextForTyres('table.front')}</td>
								<td>19"</td>
								<td>275/35 ZR 19 100 Y XL</td>
								<td>STD</td>
								<td>Michelin</td>
								<td>
									<a href="https://eprel.ec.europa.eu/qr/408484" target="_blank" rel="noreferrer">
										https://eprel.ec.europa.eu/qr/408484
									</a>
								</td>
							</tr>
							<tr>
								<td>{getTextForTyres('table.front')}</td>
								<td>19"</td>
								<td>275/35 ZR 19 100 Y XL</td>
								<td>STD</td>
								<td>Michelin</td>
								<td>
									<a href="https://eprel.ec.europa.eu/qr/410878" target="_blank" rel="noreferrer">
										https://eprel.ec.europa.eu/qr/410878
									</a>
								</td>
							</tr>
							<tr>
								<td>{getTextForTyres('table.front')}</td>
								<td>19"</td>
								<td>275/35 ZR 19 100 Y XL</td>
								<td>STD</td>
								<td>Pirelli</td>
								<td>
									<a href="https://eprel.ec.europa.eu/qr/596292" target="_blank" rel="noreferrer">
										https://eprel.ec.europa.eu/qr/596292
									</a>
								</td>
							</tr>
							<tr>
								<td>{getTextForTyres('table.rear')}</td>
								<td>20"</td>
								<td>285/30 ZR20 99Y XL</td>
								<td>STD</td>
								<td>Michelin</td>
								<td>
									<a href="https://eprel.ec.europa.eu/qr/408517" target="_blank" rel="noreferrer">
										https://eprel.ec.europa.eu/qr/408517
									</a>
								</td>
							</tr>
							<tr>
								<td>{getTextForTyres('table.rear')}</td>
								<td>20"</td>
								<td>285/30 ZR20 99Y XL</td>
								<td>STD</td>
								<td>Michelin</td>
								<td>
									<a href="https://eprel.ec.europa.eu/qr/411719" target="_blank" rel="noreferrer">
										https://eprel.ec.europa.eu/qr/411719
									</a>
								</td>
							</tr>
							<tr>
								<td>{getTextForTyres('table.rear')}</td>
								<td>20"</td>
								<td>285/30 ZR20 99Y XL</td>
								<td>STD</td>
								<td>Pirelli</td>
								<td>
									<a href="https://eprel.ec.europa.eu/qr/596291" target="_blank" rel="noreferrer">
										https://eprel.ec.europa.eu/qr/596291
									</a>
								</td>
							</tr>
						</tbody>
					</table>
					<SourceReference
						source="BMW"
						dateAccessed="2023-12-23"
						url="https://www.bmw.de/content/dam/bmw/common/topics/fascination-bmw/bmw-efficientdynamics-2020/tyre-energy-labels/pdf/G81_Reifenliste.pdf.asset.1686295995306.pdf"
					/>
				</div>
			</div>
		</div>
	);
}

export default TechnicalData;
